import PropTypes from 'prop-types';
const Heading = ({ type: Type, text, className, dataTestId, star }) => {
  return (
    <Type className={className} data-testid={dataTestId}>
      {star}
      {text}
    </Type>
  );
};
Heading.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string,
};
export default Heading;
