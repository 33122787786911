import React, { useState, useEffect } from "react";
import TopBar from "../../Atoms/TopBar";
import WorkStatus from "../../Atoms/WorkStatus";
import CustomTab from "../../Molecules/Tab";
import TimeBar from "../../Atoms/TimeBar";
import DynamicTable from "../../Atoms/Tables";
import { jwtDecode } from "jwt-decode";
import { AiOutlineEdit } from "react-icons/ai";
import { RxCross2 } from "react-icons/rx";
import axios from "axios";
import Sidebar from "../../Molecules/Sidebar";
import { useLocation } from "react-router-dom";
import { Button, Heading } from "../../Atoms/index";
import { getLocalStorageData } from "../../../constants/user";
import Loader from "../../Atoms/Loader";
import TopBars from "../../Molecules/TopBar";
import Pagination from "../../Atoms/Paginations";
import { Link, useParams, useNavigate } from "react-router-dom";
import ShowMore from "../../Atoms/Showmore";
const Timelog = () => {
  const receivedData = useParams();
  const { project_id, workitem_id } = receivedData;
  const [data, setData] = useState(null);
  const [status, setStatus] = useState("");
  const [addData, setAddData] = useState(true);
  const [editData, setEditData] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [loginData, setLoginData] = useState(null);
  const [loader, setLoader] = useState(true);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [existData, setExistData] = useState([]);
  const userId = getLocalStorageData("access_token");
  const [estimateData, setEstimate] = useState(null);
  const [serach, setSerach] = useState(false);
  const [searchParam, setsearchParam] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [error, setError] = useState(null);
  const [pageSize, setPagesize] = useState(10);
  const [dateData, setDateData] = useState('')

  let navigate = useNavigate();

  useEffect(() => {
    const token = userId !== null ? jwtDecode(userId) : "";
    setLoginData(token);
  }, [userId]);

  const headings = ["Name", "Time", "Date", "Comment", "Action"]; // type is pending

  const fetchData = async () => {

    const body = {
      UserId: loginData?.oid,
      ProjectId: project_id,
      WorkItemId: workitem_id,
      Page: page,
      Take: pageSize,
      token: userId,
      Keyword: searchParam,
      page: page,
      take: pageSize,
      FromDate: dateData.FromDate === "" ? undefined : dateData.FromDate,
      ToDate: dateData.ToDate === "" ? undefined : dateData.ToDate,
      searchParam: searchParam.name,
      userName: loginData?.name
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}TimeLog/GetAllTimeLog`,
        body
      );

      setData(response.data);
      setExistData(response?.data);
      setCount(response?.data?.count);
      setEstimate(response?.data.estimate);
      setLoader(false);
    } catch (error) {
      setError(error.response.data);
      setLoader(false);
    };
  };

  useEffect(() => {
    setLoader(true);
  }, [page, pageSize,])

  const breadcrumbData = [
    {
      name: "Projects",
      href: "/projects",
    },

    {
      name: "Work Item",
      href: `/workitems/${project_id}`,
    },
    {
      name: data?.workItemData?.title
    }
  ];


  const handleFilterClick = () => {
    setSerach(!serach)
    setLoader(!loader)
  };

  const handleFilterClear = () => {
    setLoader(!loader)
    setDateData({
      FromDate: '',
      ToDate: ''
    });
    setsearchParam("");
  }

  let cardsError = (
    <>
      <div className="bg-red text-white p-4 m-2 text-center">
        <p className="text-xl">
          {error}
        </p>
      </div>
    </>
  );

  const workItem = () => {
    fetchData();
  }

  useEffect(() => {
    if (error) {
      setTimeout(() => navigate('/projects'), 10000);
    }
  }, [error, navigate]);

  useEffect(() => {
    if (loginData) {
      fetchData();
    }
  }, [loginData, addData, page, serach, pageSize]);



  return (
    <>
      <div className="flex  md:flex-col  work-item">
        <Sidebar sidebarVisible={sidebarVisible} setSidebarVisible={setSidebarVisible} />
        <div className={`flex-grow ${sidebarVisible ? 'w-[85%] lg:w-[75%] md:w-[100%]' : 'w-[93%] lg-w:[91%]'}`}>
          <TopBars breadcrumbData={breadcrumbData} />
          <TopBar data={data?.workItemData} />
          {error && cardsError}
          <WorkStatus
            status={status}
            setStatus={setStatus}
            data={data?.workItemData}
          />
          {!error && <TimeBar
            status={status}
            setStatus={setStatus}
            handleFilterClick={handleFilterClick}
            setsearchParam={setsearchParam}
            addData={addData}
            setAddData={setAddData}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            editData={editData}
            setEditData={setEditData}
            data={receivedData}
            loginData={loginData}
            estimate={estimateData}
            serach={serach}
            setSerach={setSerach}
            searchParam={searchParam}
            loader={loader}
            setLoader={setLoader}
            dateData={dateData}
            setDateData={setDateData}
            handleFilterClear={handleFilterClear}
          />}
          {loader ? (
            <Loader />
          ) : (
            <>
              {
                count > 10 && <ShowMore pageSize={pageSize} setPagesize={setPagesize} setPage={setPage} />

              }


              {
                loader ? <Loader /> : (count > 0 ? <DynamicTable
                  searchParam={searchParam}
                  userAccessId={loginData?.oid}
                  headings={headings}
                  data={data?.timelog}
                  setIsEdit={setIsEdit}
                  setEditData={setEditData}
                  workItem={workItem}
                  setAddData={setAddData}
                  editData={editData}
                /> : <div className='text-center font-semibold text-2xl text-blue mt-8'>Timelog for this task not added</div>)
              }
            </>
          )}
          {
            count > pageSize &&
            <div className="pr-12">
              <Pagination totalItems={count} setPage={setPage} itemsPerPage={pageSize} />
            </div>
          }

        </div>
      </div>
    </>
  );
};

export default Timelog;
