import { useTable } from 'react-table';
import { BiTask, BiSolidUserCircle, BiDotsHorizontal } from 'react-icons/bi';
import { GoDotFill } from 'react-icons/go'
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useEffect } from 'react';
import { useState } from 'react';
import { FaRegClock, FaCrown, FaMinus,FaTrophy } from "react-icons/fa";
import { GiOpenBook } from "react-icons/gi";
import { isMobile } from "../../../constants/user";
import { FaPlus } from "react-icons/fa6";
import axios from 'axios';
import { FaBug } from "react-icons/fa6";
import { SlChemistry } from "react-icons/sl";
import { SiVlcmediaplayer } from "react-icons/si";

const TimelogList = ({ data, headinglist }) => {


  const _isMobile = isMobile();
  const [mobileData, setMobileData] = useState(data);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [filterData, setFilterData] = useState(data);

  const [active, setActive] = useState(true);

  useEffect(() => {
    setFilterData(data);
  }, [data])

  const handleMouseOver = (type) => {
    let task, userStory, epic;
    switch (type) {
      case 'Task':
        task = 'Task';
        userStory = null;
        epic = null;
        break;
      case 'User Story':
        task = null;
        userStory = 'User Story';
        epic = null;
        break;
      case 'Epic':
        task = null;
        userStory = null;
        epic = 'Epic';
        break;
      default:
        task = null;
        userStory = null;
        epic = null;
        break;
    }
    setHoveredItem({ task, userStory, epic });
  };

  const toggleview = (projectIndex) => {
    const updatedData = [...mobileData];
    updatedData[projectIndex].toggle = !updatedData[projectIndex].toggle;
    setMobileData(updatedData);
  };


  const workItemicon = (title) => {
    if (title == 'User Story') {
      return <GiOpenBook title = 'User Story' className='text-blue' />
    }
    else if (title == 'Task') {
      return <BiTask title = 'Task' className='text-yellow' />
    }
    else if (title == 'Subtask') {
      return <BiTask title="subtask" className='text-[#E5C313]' />
    }
    else if (title == 'Test Case') {
      return <SlChemistry title = 'Test Case' className='text-[#3bc24d]' />
    }
    else if (title == 'Feature') {
      return <FaTrophy title = 'Feature' className='text-[#9b55eb]' />
    }
    else if (title == 'Epic') {
      return <FaCrown title ='Epic'  className='text-[#f3a736]' />
    }
    else if (title == 'Issue') {
      return <SiVlcmediaplayer title='Issue' className='text-[#b600a0]' />
    }
    else {
      return <FaBug title='Bug' className='text-yellow'/>
    }
  }

  const convertSecondsToHoursAndMinutes = (minutes) => {
    const checkMinutes = Math.abs(minutes);
    const hours = Math.floor(checkMinutes / 60);
    const remainingMinutes = checkMinutes % 60;

    return hours < 0 ? `${minutes < 0 ? '-' : ''}${hours} hr` : `${minutes < 0 ? '-' : ''}${hours} hr ${remainingMinutes} min`;
  };

  const sortingWorkItemId = async (sortingOrder) => {


    if (sortingOrder === 'asc') {
      const sortedData = [...filterData]?.sort((a, b) => a.workitemId - b.workitemId);
      setFilterData(sortedData);
      setActive(true)
    } else if (sortingOrder === 'desc') {
      const sortedData = [...filterData]?.sort((a, b) => b.workitemId - a.workitemId);
      setFilterData(sortedData);
      setActive(false)
    }
    return;
  }


  return (
    <div class="relative overflow-x-auto pl-3 lg:pl-0 md:p-3">
      {_isMobile ?
        <div>
          {mobileData?.map((item, projectIndex) => (
            <>
              <div className="flex items-center justify-between  border border-b border-white">
                <span className="text-[12px] bg-secbg w-24 px-1 py-2">
                  ID
                </span>

                <div className="bg-lightgray w-full rounded-sm px-1 py-[8px] flex justify-between items-center">
                  <span className="text-[12px] flex items-center justify-between w-full">
                    {item.workitemId}

                    <span onClick={() => toggleview(projectIndex)}>{item?.toggle ? (
                      <FaMinus className="text-sm" />
                    ) : (
                      <FaPlus className="text-sm" />
                    )}
                    </span>
                  </span>
                </div>
              </div>

              {item?.toggle && (

                <>
                  <div className="flex items-center justify-between  border border-b border-white">
                    <span className="text-[12px] bg-secbg w-24 px-1 py-2">
                      Title
                    </span>

                    <div className="bg-lightgray w-full rounded-sm px-1 py-[8px] flex justify-between items-center">
                      <span className="text-[12px]">
                        <Link to={`/timelog/${item.projectId}/${item.workitemId}`} className='hover:underline md:text-sm sm:w-40 sm:truncate sm:block' >{item.title}</Link>
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center justify-between  border border-b border-white">
                    <span className="text-[12px] bg-secbg w-24 px-1 py-2">
                      Assigned To
                    </span>

                    <div className="bg-lightgray w-full rounded-sm px-1 py-[8px] flex justify-between items-center">
                      <span className="text-[12px]">
                        {item.assinedTo}
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center justify-between  border border-b border-white">
                    <span className="text-[12px] bg-secbg w-24 px-1 py-2">
                      State
                    </span>

                    <div className="bg-lightgray w-full rounded-sm px-1 py-[8px] flex justify-between items-center">
                      <span className="text-[12px]">
                        <div className='flex gap-1 items-center'><GoDotFill className='text-blue text-[12px]' />{item.state}</div>
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center justify-between  border border-b border-white">
                    <span className="text-[12px] bg-secbg w-24 px-1 py-2">
                      Area Path
                    </span>

                    <div className="bg-lightgray w-full rounded-sm px-1 py-[8px] flex justify-between items-center">
                      <span className="text-[12px]">
                        <div className='flex gap-1 items-center'>{item.projectName}</div>
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center justify-between  border border-b border-white">
                    <span className="text-[12px] bg-secbg w-24 px-1 py-2">
                      Tags
                    </span>

                    <div className="bg-lightgray w-full rounded-sm px-1 py-[8px] flex justify-between items-center">
                      <span className="text-[12px]">
                        <div className='flex gap-1 items-center'>{item.tags || '--'}</div>
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center justify-between  border border-b border-white">
                    <span className="text-[12px] bg-secbg w-24 px-1 py-2">
                      Comment
                    </span>

                    <div className="bg-lightgray w-full rounded-sm px-1 py-[8px] flex justify-between items-center">
                      <span className="text-[12px]">
                        <div className='flex gap-1 items-center'>{item.comment || '--'}</div>
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center justify-between  border border-b border-white">
                    <span className="text-[12px] bg-secbg w-24 px-1 py-2">
                      Activity Date
                    </span>

                    <div className="bg-lightgray w-full rounded-sm px-1 py-[8px] flex justify-between items-center">
                      <span className="text-[12px]">
                        {/* <div className='flex gap-1 items-center'>{item.activityDate == '0001-01-01T00:00:00' ? '--' : moment(item.activityDate).format("MM/DD/YYYY")}</div> */}
                        <div className='flex gap-1 items-center'>{item.type}</div>
                      </span>
                    </div>
                  </div>
                  <div className="flex items-center justify-between  border border-b border-white">
                    <span className="text-[12px] bg-secbg w-24 px-1 py-2">
                      Time
                    </span>

                    <div className="bg-lightgray w-full rounded-sm px-1 py-[8px] flex justify-between items-center">
                      <span className="text-[12px]">
                        <div className='flex items-center gap-1 '>
                          {item.activityDate == '0001-01-01T00:00:00' ? '--' : moment(item.activityDate).format("h:mm A")}
                        </div>
                      </span>
                    </div>
                  </div>
                </>
              )}
            </>
          ))}

        </div> : <div>
          <table class="w-full text-sm text-left text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400 border-gray bg-secbg border-t whitespace-nowrap overflow-hidden text-overflow-ellipsis">
              <tr>
                {headinglist?.map((headinglist, index) => (
                  <th scope="col" class="px-3 py-4 md:py-1 md:px-1 border border-gray  border-t-0 border-b-0 capitalize ">
                    {headinglist}
                    {headinglist == 'ID' && (
                      <>
                        <span className={`cursor-pointer text-xl font-bold ${active ? 'text-yellow' : 'text-black'}`} onClick={() => sortingWorkItemId('asc')}> ↑</span>
                        <span className={`cursor-pointer text-xl font-bold ${!active ? 'text-yellow' : 'text-black'}`} onClick={() => sortingWorkItemId('desc')}>↓</span>
                      </>
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {filterData?.map((item, index) => (

                <tr className='bg-white  dark:bg-gray-800 dark:border-gray-700 hover:bg-gray  ' key={index}>
                  <td className='px-4 py-4 md:py-1 md:px-1 border border-gray'>{item.workitemId}</td>
                  <td className='px-4 py-4 md:py-1 md:px-1 cursor-auto border md:text-sm border-gray' ><div className='flex  justify-between '>
                    <div className='flex gap-1 items-center'>

                      <h2 onMouseOver={() => handleMouseOver(item.type)} title={hoveredItem && `${hoveredItem.task || hoveredItem.userStory || hoveredItem.epic}`}>{workItemicon(item.type)}</h2>
                      <Link to={`/timelog/${item.projectId}/${item.workitemId}`} className='hover:underline md:text-sm sm:w-56 sm:truncate ' >{item.title}</Link>
                    </div>
                    </div>
                  </td>
                  <td className='px-4 md:py-2 md:px-2 md:text-sm py-4 border border-gray whitespace-nowrap overflow-hidden text-overflow-ellipsis'>{item.type} </td>
                  <td className='px-4 md:py-2 md:px-2 md:text-sm py-4 border border-gray '><div className='flex items-center justify-between'><div className='flex gap-1 items-center'><BiSolidUserCircle className='text-darkgray text-xl text-green' />{item.assinedTo}</div> </div></td>
                  <td className='px-4 md:py-2 md:px-2 md:text-sm py-4 border border-gray'><div className='flex gap-1 items-center'>{item.state}</div></td>
                  <td className='px-4 md:py-2 md:px-2 md:text-sm py-4 border border-gray'>{item.projectName}</td>
                  <td className="px-4 md:py-2 md:px-2 md:text-sm py-4 border border-gray">
                    {Math.floor(item.originalEstimate / 60)} hr

                  </td>
                  <td className={`px-4 md:py-2 md:px-2 md:text-sm py-4 border border-gray }`}>
                    {convertSecondsToHoursAndMinutes(item.spent)}
                  </td>
                  <td className={`px-4 md:py-2 md:px-2 md:text-sm py-4 border border-gray overflow-x-auto whitespace-nowrap ${item.remaining < 0 ? 'text-red' : ''}`}>
                    {convertSecondsToHoursAndMinutes(item.remaining)}
                  </td>
                  {/* <td className='px-4 md:py-2 md:px-2 md:text-sm py-4 border border-gray whitespace-nowrap overflow-hidden text-overflow-ellipsis'>{item.activityDate === '0001-01-01T00:00:00' ? '--' : moment(item.activityDate).format('MM/DD/YYYY h:mm A')} test</td> */}
                 
                </tr>
              ))}
            </tbody>
          </table>
        </div>}

    </div>

  );
};

export default TimelogList;



