import React from "react";
import { useEffect } from "react";
import { BiSolidTime } from "react-icons/bi";
import { jwtDecode } from "jwt-decode";
import TimelogTable from "../../Atoms/TimlogTable";
import { RxCross2 } from "react-icons/rx";
import { AiOutlineEdit } from "react-icons/ai";
import { useState } from "react";
import axios from "axios";
import Sidebar from "../../Molecules/Sidebar";
import { Button, Heading } from "../../Atoms/index";
import moment from "moment";
import { useNavigate } from "react-router";
import { dateFormat } from "../../../constants/user";
import { FaFileExport } from "react-icons/fa6";
import * as XLSX from "xlsx";
import { convertMinutesToHoursAndMinutes } from "../../../utils/convertMinToHrs";
import {
  getLocalStorageData,
  setLocalStorageData,
  clearLocalStorage,
} from "../../../constants/user";

import { GrDocumentTime } from "react-icons/gr";
import TopBar from "../../Molecules/TopBar";
import Loader from "../../Atoms/Loader";
import { isMobile } from "../../../constants/user";
import { toast } from "react-toastify";



const TimelogSummary = () => {

  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [searchData, setSearchdata] = useState({});
  const [search, setSearch] = useState(false);
  const [project, setProject] = useState([]);
  const [loginData, setLoginData] = useState(null);
  const [loader, setLoader] = useState(true);
  const [projectUser, setProjectUser] = useState([]);
  const [adUsers, setAdusers] = useState([]);
  const _isMobile = isMobile();
  const [userId, setUserId] = useState();
  const refresh_token = getLocalStorageData("refresh_token");
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [projectName,setProjectName] = useState([])
  const [userName,setuserName] = useState([])
  const [reportFilter, setReportFilter] = useState([]);

  useEffect(() => {
    if (userId) {
      const token = userId !== null ? jwtDecode(userId) : "";

      setLoginData(token);

      fetchADUsers();
    }
  }, [userId]);

  const selectMonth = (fromdate, month) => {
    if (fromdate) {
      setSearchdata({ ...searchData, month: "select" });

      return "";
    } else {
      if (month == "select") {
        return "";
      } else if (month?.length > 0) {
        return `&month=${month}`;
      } else {
        return `&month=${moment().month() + 1}`;
      }
    }
  };

  const selectYear = (monthText, fromdate) => {
    if (fromdate) {
      return moment(fromdate).year();
    } else if (monthText) {
      return moment(monthText, "MM-YYYY").year();
    } else {
      return moment().year();
    }
  };

  const selectUser = () => {
    const { UserId, project } = searchData;

    if (!UserId && !project) {
      return loginData?.oid;
    }

    if (project && !UserId) {
      return "";
    }

    return UserId;
  };

  const fetchSearchData = async () => {
    try {
      const { project, month, fromdate, todate, monthText, UserId } =
        searchData;

      const response = await axios.get(
        `${
          process.env.REACT_APP_BASE_URL
        }TimeLog/GetAllTimeLogFiltered?UserId=${selectUser()}${
          project?.length > 0 ? `&ProjectId=${project}` : ""
        }${selectMonth(fromdate, month)}${
          fromdate?.length > 0 ? `&fromdate=${fromdate}` : ""
        }${todate?.length > 0 ? `&todate=${todate}` : ""}&year=${selectYear(
          monthText,
          fromdate
        )}`
      );

      response?.data?.map((x) => {
        x.toggle = false;

        return x;
      });

      setData(response.data);

      setLoader(false);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProjectData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}TimeLog/GetAllProjects`
      );

      setProject(response.data.value);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProjectUsers = async () => {
    try {
      const { project } = searchData;

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}TimeLog/GetAllUsersByProject?ProjectId=${project}`
      );

      setProjectUser(response?.data?.value);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchADUsers = async () => {
    try {
      const exptime = getLocalStorageData("expiredTime");

      if (exptime < new Date().toISOString()) {
        try {
          const response = await axios.post(
            `${process.env.REACT_APP_BASE_URL}Login/GetRefreshToken`,
            { refreshToken: refresh_token }
          );

          const { access_token, expires_in } = response?.data?.result;

          setLocalStorageData(
            new Date(new Date().getTime() + expires_in * 1000),

            "expiredTime"
          );

          setLocalStorageData(
            access_token,

            "access_token"
          );

          setUserId(getLocalStorageData("access_token"));

          const adresponse = await axios.post(
            `${process.env.REACT_APP_BASE_URL}TimeLog/GetAllADUsers`,
            { token: access_token }
          );

          setAdusers(adresponse.data.value);
        } catch (error) {
          console.error("Error fetching refresh token:", error);
        }
      } else {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}TimeLog/GetAllADUsers`,
          { token: userId }
        );

        setAdusers(response.data.value);
      }
    } catch (error) {
      console.log(error);
    }
  };

  let projectLabel = project?.find((item) => item.id === searchData.project)?.name;

  let userNameLabel =  adUsers?.find((item) => item.id === searchData.UserId)?.displayName;

  if(userNameLabel == undefined && projectLabel !== undefined){
    userNameLabel = searchData?.project ? userNameLabel : loginData?.name;
  } else if (userNameLabel === undefined && projectLabel === undefined) {
    userNameLabel = loginData?.name
  } else if (projectLabel !== undefined && userNameLabel === undefined) {
    userNameLabel = ''
  }

  const fetchSummaryReportData = async () => {
    setLoader(true);
    let message;
   if(projectLabel){
    if(userNameLabel){
      message =  `${projectLabel}_${userNameLabel}`
    }
    else{
      message = projectLabel;
    }
   }
   else{
    message = userNameLabel;
   }
  
    try {
      const { project, month, fromdate, todate, monthText, UserId } = searchData;
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}TimeLog/GetSummaryReport?UserId=${selectUser()}${
          project?.length > 0 ? `&ProjectId=${project}` : ""
        }${selectMonth(fromdate, month)}${
          fromdate?.length > 0 ? `&fromdate=${fromdate}` : ""
        }${todate?.length > 0 ? `&todate=${todate}` : ""}&year=${selectYear(
          monthText,
          fromdate
        )}`,
        
        {},
        { responseType: "blob" } 
        
      );
      
      setLoader(false);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      const filename = `${message}_Summary_Report.xlsx`; 
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
  
      link.remove();
  
    
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };
  
  useEffect(() => {
    if (loginData) {
      setLoader(true);

      fetchSearchData();
    }
  }, [search, loginData]);

  useEffect(() => {
    setUserId(getLocalStorageData("access_token"));
    fetchProjectData();
  }, []);

  useEffect(() => {
    const { project } = searchData;

    const emptyState = () => {
      setProjectUser([]);

      setSearchdata({ ...searchData, UserId: "" });
    };

    if (project) {
      emptyState();

      fetchProjectUsers();
    } else {
      fetchADUsers();

      emptyState();
    }
  }, [searchData?.project]);

  const clearFilter = () => {
    setProjectUser([]);

    setSearchdata({});

    setSearch(!search);

    setAdusers([]);

    fetchADUsers();
  };

  const projectUserdata = (value) => {
    const adUser = adUsers.find((item) => item.displayName === value);

    setSearchdata({ ...searchData, UserId: adUser ? adUser.id : "" });
  };

  const breadcrumbData = [
    {
      name: "Timelog Summary",

      href: "/timelogsummary",
    },
  ];

  const currentMonth = moment().month() + 1;

  const previousTwoMonths = [
    moment().subtract(1, "months").month() + 1,

    moment().subtract(2, "months").month() + 1,
  ];

  const monthOptions = [
    { value: "select", text: "Select Month" },

    { value: currentMonth, text: moment().format(dateFormat) },

    {
      value: previousTwoMonths[0],

      text: moment().subtract(1, "months").format(dateFormat),
    },

    {
      value: previousTwoMonths[1],

      text: moment().subtract(2, "months").format(dateFormat),
    },
  ];

  const handleClickExport = () => {
    fetchSummaryReportData();
  
  };


  return (
    <div className="flex md:flex-col">
      <Sidebar
        sidebarVisible={sidebarVisible}
        setSidebarVisible={setSidebarVisible}
      />
     
      <div
        className={`flex-grow ${
          sidebarVisible
            ? "w-[85%] lg:w-[75%] md:w-[100%]"
            : "w-[93%] lg-w:[91%]"
        }`}
      >
        <TopBar breadcrumbData={breadcrumbData} />

        <div className="flex items-center gap-2 p-4">
          <GrDocumentTime className="text-yellow text-2xl timeicon " />
          <div className="flex justify-between w-full">
            <Heading
              type={"h1"}
              className={`font-medium text-xl`}
              text={"Timelog Summary"}
              dataTestId={"Best-seller"}
            />
            {data.length > 0 && (
              <div className="flex text-darkgray text-sm justify-end">
                <span className="flex items-center bg-gray px-2 rounded-sm ">
                  {" "}
                  <FaFileExport className="text-yellow cursor-pointer" />
                  <Button
                    label={"Export"}
                    onClick={() => {
                      handleClickExport();
                    }}
                    className={
                      "  font-medium py-1 px-4 rounded  pt-1 md:w-full"
                    }
                  />
                </span>
              </div>
            )}
          </div>
        </div>
       
        <div className="p-4">
          <div className="flex gap-2 items-center filter-sections bg-background px-6 md:px-2 py-4 md:flex-wrap ">
            <div className="filter-bar">
              <div className="flex flex-col">
                <label className="text-[12px]">Project</label>

                <select
                  className="border  text-[12px] w-full  h-[35px] rounded-md border-gray text-black   focus:appearance-show md:w-[200px] sm:w-full"
                  id="selectBox"
                  value={searchData?.project || ""}
                  onChange={(e) =>
                    setSearchdata({ ...searchData, project: e.target.value })
                  }
                >
                  <option value="">Select</option>

                  {project?.map((option, index) => (
                    <option key={index} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="flex flex-col">
              <label className="text-[12px]">Users</label>

              <select
                key={searchData ? searchData.project : " "}
                className="border  text-[12px] w-full  h-[35px] rounded-md border-gray text-black  focus:appearance-show md:full sm:w-full"
                id="selectBox"
                onChange={(e) => projectUserdata(e.target.value)}
              >
                <option value="">Select</option>

                {projectUser?.length > 0 ? (
                  projectUser?.map((option, index) => (
                    <option key={index} value={option.displayName}>
                      {option.displayName}
                    </option>
                  ))
                ) : (
                  <>
                    {adUsers?.map((option, index) => (
                      <option
                        key={index}
                        value={option.displayName}
                        selected={option.id === loginData?.oid}
                      >
                        {option.displayName}
                      </option>
                    ))}
                  </>
                )}
              </select>
            </div>

            <div className="filter-bar">
              <div className="flex flex-col">
                <label className="text-[12px]">Month</label>

                <select
                  className="border  text-[12px] w-full h-[35px] rounded-md border-gray text-black focus:appearance-show"
                  id="selectBox"
                  value={
                    searchData?.month ? searchData?.month : moment().month() + 1
                  }
                  onChange={(e) =>
                    setSearchdata({
                      ...searchData,
                      month: e.target.value,
                      monthText: e.target.options[e.target.selectedIndex].text,
                      fromdate: "",
                      todate: "",
                    })
                  }
                >
                  {monthOptions?.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.text}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="flex items-center sm:w-[100%]">
              <div className="flex flex-col flex-grow ">
                <label className="text-[12px]">Date </label>

                <div className="flex gap-2">
                  <input
                    placeholder="From"
                    type="Date"
                    name="hours"
                    id="hours"
                    min="0"
                    step="1"
                    className="timeTextBox flex-grow pl-1 border  text-[12px] h-[35px] rounded-md border-gray text-black"
                    value={searchData?.fromdate || ""}
                    onChange={(e) =>
                      setSearchdata({ ...searchData, fromdate: e.target.value })
                    }
                  ></input>

                  <input
                    placeholder="to"
                    type="Date"
                    name="hours"
                    id="hours"
                    min="0"
                    step="1"
                    className="timeTextBox  flex-grow pl-1 border h-[35px] rounded-md border-gray text-black text-[12px]"
                    value={searchData?.todate || ""}
                    onChange={(e) =>
                      setSearchdata({ ...searchData, todate: e.target.value })
                    }
                  ></input>
                </div>
              </div>
            </div>

            <Button
              label={"Search "}
              className={
                "bg-yellow text-sm hover:bg-blue-700 text-white  py-1 px-4 sm:py-2 rounded mt-[14px] h-[35px]"
              }
              onClick={() => setSearch(!search)}
            />

            <Button
              label={"Reset"}
              className={
                "text-white text-sm bg-blue py-1 sm:py-2 px-1 md:px-2 rounded sm:ml-[1px]  mt-[14px] h-[35px]"
              }
              onClick={clearFilter}
            />
          </div>

          {loader ? <Loader /> : <TimelogTable data={data} />}
        </div>
      </div>
    </div>
  );
};

export default TimelogSummary;
