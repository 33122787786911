import {React,useState} from 'react'
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { getLocalStorageData, clearLocalStorage } from '../../../constants/user';
import { jwtDecode } from "jwt-decode";
import { Button} from '../../Atoms/index';
import { FaSignOutAlt } from "react-icons/fa";
import UserName from '../UserName'
function LoginButton() {
    const navigate = useNavigate();
  const [loginData, setLoginData] = useState(null);
  const userId = getLocalStorageData('access_token');

  useEffect(() => {
    const token = userId !== null ? jwtDecode(userId) : '';
    setLoginData(token);
  }, [userId]);

  function getInitials(fullName) {
    const words = fullName.split(' ');
    const initials = words.map((word) => word.charAt(0)).join('');
    return initials.toUpperCase();
  }

  const logOut = () => {
    clearLocalStorage();
    navigate('/');
  }

  return (
    <div>
        <div className='rounded-full flex items-center'>
          {loginData && <Button label={<FaSignOutAlt className='text-yellow text-2xl ' title='Log Out' />} className={'  px-[12px] py-[12px]   hover:bg-blue-700 text-black font-bold ml-2'} onClick={logOut} />}
        </div>
    </div>
  )
}

export default LoginButton;