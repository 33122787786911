import React, { useState } from 'react';


const ShowMore = ({pageSize,setPagesize,setPage}) => {
    const handleItemsPerPageChange = (e) => {
        setPagesize(parseInt(e.target.value));
        setPage(1);
      };


    return (
        <div className='text-right p-2'>
            <label htmlFor="itemsPerPage" className='font-semibold p-2'>Items Per Page :</label>
            <select
                id="itemsPerPage"
                value={pageSize}
                onChange={handleItemsPerPageChange}
                className='border-2 border-lightblue'
            >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="30">30</option>
            </select>
        </div>
    );
}

export default ShowMore;
