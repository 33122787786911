import React, { useState, useEffect } from "react";
import Sidebar from "../../Molecules/Sidebar";
import { Button, Heading } from "../../Atoms/index";
import axios from "axios";
import TopBar from "../../Molecules/TopBar";
import { RiStackLine } from "react-icons/ri";
import { IoFilterSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import Loader from "../../Atoms/Loader";
import { jwtDecode } from "jwt-decode";
import { getLocalStorageData } from "../../../constants/user";


const Projects = () => {
  const [project, setProject] = useState([]);
  const [loader, setLoader] = useState(true);
  const [fillterData, setFilterData] = useState([]);
  const [url, setUrl] = useState();
  const [loginData, setLoginData] = useState(null);
  const [sidebarVisible, setSidebarVisible] = useState(true);

  const userId = getLocalStorageData("access_token");

  useEffect(() => {
    const token = userId !== null ? jwtDecode(userId) : "";
    setLoginData(token);
  }, [userId]);

  useEffect(() => {
    if (loginData) {
      fetchData();
    }
  }, [loginData]);


  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}TimeLog/GetProjectByUserName?UserName=${loginData?.name}`
      );
      setProject(response.data.value);
      setFilterData(response.data.value);
      setLoader(false);
    } catch (error) {
      console.log(error);
    }
  };



  const breadcrumbData = [
    {
      name: 'Projects',
      href: '/projects',
    }
  ];

  function getInitials(fullName) {
    const words = fullName.split(" ");
    const initials = words.map((word) => word.charAt(0)).join("");
    return initials.toUpperCase();
  }

  const filterBySearch = (event) => {
    const query = event.target.value;
    const updatedList = project.filter(
      (item) => item.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
    setFilterData(updatedList);
  };



  return (
    <>
      <div className="flex md:flex-col">
        <Sidebar sidebarVisible={sidebarVisible} setSidebarVisible={setSidebarVisible} />
        <div className={`flex-grow ${sidebarVisible ? 'w-[85%] lg:w-[75%] md:w-[100%] ' : 'w-[93%] lg-w:[91%]'}`}>
          <TopBar breadcrumbData={breadcrumbData} />
          <div className="ml-4 pr-4 md:ml-0 md:p-4">
            <div className="flex gap-2 items-center px-3 py-3 pr-0 md:pl-0 justify-between md:flex-col md:w-full md:items-baseline">
              <div className="flex gap-2 items-center">
                <RiStackLine className="text-yellow text-3xl" />
                <Heading
                  type={"h1"}
                  className={`font-bold text-2xl `}
                  text={"Projects"}
                  dataTestId={"Best-seller"}
                />
              </div>
              <div className="border border-gray rounded-sm h-8 flex items-center pr-4 w-1/5 md:w-full">
                <input
                  type="search"
                  placeholder="Enter Keyword Here"
                  className="border-none w-full pl-1 focus:outline-none  h-7 search-input"
                  onChange={(event) => filterBySearch(event)}
                />
                <IoFilterSharp className="filters" />
              </div>
            </div>
            <div className="grid grid-cols-4 gap-4 pt-2 md:grid-cols-1  mb-4">
              {loader ? <Loader /> : fillterData?.map((item) => {
                return (
                  <>
                    <div>
                      <div className=" bg-background hover:shadow-md">
                        <ul className="text-left">
                          <li className="font-bold">
                            <Link
                              to={`/workitems/${item.id}`}
                            >
                              <div className="card py-8 px-4 flex flex-col gap-6">
                                <div className="text-white ">
                                  <span className="bg-yellow px-4 py-3 rounded">
                                    {getInitials(item.name)}
                                  </span>
                                </div>
                                {item.name}
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Projects;
