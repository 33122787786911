import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Movetime from '../../Molecules/Movetime'
import Sidebar from '../../Molecules/Sidebar';
import { useLocation } from 'react-router-dom';
import { Button, Heading } from '../../Atoms/index';
import TopBar from '../../Molecules/TopBar';
import AccessPage from '../AccessPage';
import axios from 'axios';
import { getLocalStorageData } from '../../../constants/user';
import { jwtDecode } from 'jwt-decode';
import Loader from '../../Atoms/Loader';


const Movetimelog = () => {


    const [menus, setMenusData] = useState([]);
    const [loginData, setLoginData] = useState(null);
    const [loader, setLoader] = useState(true);
    const [sidebarVisible, setSidebarVisible] = useState(true);


    const currentUserId = getLocalStorageData("access_token");

    useEffect(() => {
        const token = currentUserId !== null ? jwtDecode(currentUserId) : "";
        setLoginData(token);
    }, [currentUserId]);


    const fetchMenusData = async () => {
        try {

            const response = await axios.get(
                `${process.env.REACT_APP_BASE_URL}AclRecord/GetPermissionMenu?userId=${loginData?.oid}`
            );

            if (response.status == 200) {
                setMenusData(response?.data)
                setLoader(false)

            }
        } catch (error) {
            console.log(error);
            setLoader(false)
        }
    }

    useEffect(() => {
        if (loginData) {
            fetchMenusData()
        }

    }, [loginData])



    const breadcrumbData = [
        {
            name: 'Move Timelog',
            href: '/moveTimelog',
        }
    ];

    if (loader) {
        return <div> <Loader text="loading..." /></div>;
    }

    return <>
        <div className='flex  md:mt-0 md:flex-col'>
            <Sidebar sidebarVisible={sidebarVisible} setSidebarVisible={setSidebarVisible} />
            <div className='w-[85%] md:w-full flex-grow'>
                <TopBar breadcrumbData={breadcrumbData} />
                {menus && menus?.showMoveTimeLog === true ? <Movetime /> : <AccessPage />}
            </div>
        </div>
    </>
}

export default Movetimelog;