import React, { useState, useEffect } from 'react'
import { FaScrewdriver, FaRecycle, FaBars, FaFilter } from 'react-icons/fa';
import { BsChevronDown, BsPlusLg, BsArrow90DegRight } from 'react-icons/bs'
import { MdOutlineOpenInFull } from 'react-icons/md'
import { FiFilter } from 'react-icons/fi'
import FilterBar from '../FilterBar'
import TimelogList from '../TimelogList'
import axios from 'axios';
import { TfiLayoutListThumb } from "react-icons/tfi";
import { IoFilterSharp } from "react-icons/io5";
import Loader from "../../Atoms/Loader";
import Pagination from '../Paginations';
import { CSSTransition } from 'react-transition-group';
import { useSelector, useDispatch } from 'react-redux';
import { updateFilter } from '../../../store/workItem';
import { filter } from 'style-value-types';
import ShowMore from '../Showmore';
import { useNavigate,useSearchParams,useLocation  } from 'react-router-dom';
const WorkItem = ({ projectId }) => {
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [filterData, setFilterData] = useState([]);
  const [searchParams] = useSearchParams();
  const [page, setPage] = useState(Number(searchParams.get('page')));
  const [count, setCount] = useState(1);
  const [pageSize, setPagesize] = useState(10);
   
  const [path, setPath] = useState('');
  const headinglist = ['ID', 'Title', 'Workitem Type', 'Assigned to', 'Status', 'Area Path', 'Original Estimate', 'Completed', 'Remaining']
   
  const workFilter = useSelector((state) => state.filter.workFilter);
  const location = useLocation();

  const buttons = [
    { label: 'New Work Item', icon: <BsPlusLg className='text-blue' /> },
    { label: 'Open in Queries', icon: <BsArrow90DegRight className='text-blue' /> },
    { label: 'Column Options', icon: <FaScrewdriver className='text-blue' /> },
    { label: 'Recycle Bin', icon: <FaRecycle className='text-blue' /> }
  ];

  const dispatch = useDispatch();

  const fetchData = async (keyword) => {
    try {
     
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}TimeLog/GetAllWorkItemByProjectId`, {
        "projectId": projectId,
        "page": !keyword ? page : page,
        "take": pageSize, ...workFilter,
       
      });
      if (response.status == 200) {
        const sortedData = response?.data?.data.sort((a, b) => a.workitemId - b.workitemId);
        setData(sortedData);
        setCount(response?.data?.count)
        setLoader(false);
      }
    }
    catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    const hasFilterData = Object.keys(filterData).some(key => filterData[key]);
    if (hasFilterData) {
      dispatch(updateFilter({ "projectId": projectId, ...filterData }));
    }
  }, [filterData])


  useEffect(() => {
    if (projectId !== workFilter.projectId) {
      dispatch(updateFilter([]));
    }
  }, [])

  useEffect(() => {
    setLoader(true);
    fetchData();
  }, [page, workFilter, pageSize])

  const navigate = useNavigate();
  
  const handleClickPginate = (selectedPage) => {// Pass query parametersnavigate('/path?param1=value1&param2=value2');
   const newPath = `${window.location.pathname}?page=${selectedPage}`
    navigate(newPath);
    setPath( newPath);
  
  }

  return (
    <div className='pr-6 md:p-2'>
      <div className='m-3 mr-0 md:ml-0 md:px-3 '>
        <div className='flex items-center gap-3 justify-between py-2 ml-2'>
          <div className='flex items-center gap-3'>
            <TfiLayoutListThumb className='text-yellow timeicon text-2xl' />
            <strong className='text-center text-xl'>Work Items</strong>
          </div>
        </div>
      </div>
      {
        <FilterBar filterData={filterData} setFilterData={setFilterData} />
      }

      {
        data.length > 0 && <ShowMore pageSize={pageSize} setPagesize={setPagesize} setPage={setPage} />
      }

      {
        loader ? <Loader /> : (data.length > 0 ? <TimelogList data={data} headinglist={headinglist} /> : <div className='text-center font-semibold text-2xl text-blue mt-8'>No Workitem Found</div>)
      }


      {count > pageSize && <Pagination handleClickPginate={handleClickPginate} page={page}  totalItems={count} setPage={setPage} itemsPerPage={pageSize} />}

    </div>
  )
}

export default WorkItem