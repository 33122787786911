import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { BiSolidTime } from "react-icons/bi";
import ProgressBar from "@ramonak/react-progress-bar";
import { IoIosSave } from "react-icons/io";
import { Input, Select } from "../index";
import { useSelect } from "@material-tailwind/react";
import axios from "axios";
import { Button } from "../index";
import { FaRegClock } from "react-icons/fa";
import { FaSearch } from "react-icons/fa";
import moment from "moment";
import { RxCross2 } from "react-icons/rx";
import { jwtDecode } from "jwt-decode";
import {
  getLocalStorageData,
  setLocalStorageData,
} from "../../../constants/user";
const TimeBar = ({
  status,
  addData,
  setAddData,
  editData,
  setEditData,
  data,
  loginData,
  estimate,
  isEdit,
  setIsEdit,
  setFilteredData,
  setsearchParam,
  searchParam,
  handleFilterClick,
  setTableData,
  serach,
  setSerach,
  loader,
  setLoader,
  dateData,
  setDateData,
  handleFilterClear
}) => {
  const { project_id, workitem_id } = data;
  const [error, setError] = useState({});
  const [taskData, setTaskData] = useState({
    userId: loginData?.oid,
    ticketHours: 0,
    ticketMinutes: 0,
    projectId: project_id,
    workItemId: workitem_id,
    timeLogDate: moment().format("YYYY-MM-DD"),
    CreatedBy: loginData?.name,
    ticketState: status,
    userName: loginData?.name
  });
  const [InputValue, setInputValue] = useState([]);
  const [selectEnum, setSelectEnum] = useState([]);
  const [selectedIds, setSelectedIds] = useState([]);
  const estimates = estimate;

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}TimeLog/GetProjectAndWorkItemEnum`
      );
      setSelectEnum(response.data?.workItemEnum);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setTaskData({
      ...taskData,
      userId: loginData?.oid,
      CreatedBy: loginData?.name,
    });
  }, [loginData]);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    GetDaysRestrictionOnTimeLog();
    GetExistingRestrictedProjects();
  }, [loader]);


  useEffect(() => {
    setTaskData((prevTaskData) => ({
      ...prevTaskData,
      ticketState: status,
      userName: loginData?.name
    }));

  }, [status]);

  useEffect(() => {
    setTaskData((prevTaskData) => ({
      ...prevTaskData,
      ticketState: status,
      ticketHours: editData?.ticketHours,
      ticketMinutes: editData?.ticketMinutes,
      timeLogDate: editData?.timeLogDate
        ? editData.timeLogDate
        : moment().format("YYYY-MM-DD"),
      workItemType: editData?.workItemType,
      comment: editData?.comment,
      userName: loginData?.name
    }));

  }, [editData]);




  const GetDaysRestrictionOnTimeLog = async () => {

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}Setting/GetDaysRestriction`,
      );

      setInputValue(response.data);


    } catch (error) {
      console.error("Error fetching refresh token:", error);
    }

  };

  const GetExistingRestrictedProjects = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}Setting/GetExistingRestrictedProjects?CurrentUserId=${loginData?.oid}`,
      );

      setSelectedIds(response.data);

    } catch (error) {
      console.error("Error fetching refresh token:", error);
    }

  };

  const validateData = () => {
    const errors = {};
    let isValid = true;

    if (
      (!taskData?.ticketHours || parseInt(taskData?.ticketHours, 10) === 0) &&
      (!taskData?.ticketMinutes || parseInt(taskData?.ticketMinutes, 10) === 0)
    ) {
      errors.timeLog = "Please enter the hours and minutes for your time log";
      isValid = false;
    }

    if (!taskData.timeLogDate) {
      errors.timeLogDate = 'Please select a date'
      isValid = false;
    }


    if (!taskData.workItemType) {
      errors.workItemType = "Please select a task from the dropdown menu";
      isValid = false;
    }

    if (!taskData.comment) {
      errors.comment = 'Please enter a comment for the task'
      isValid = false;
    }



    if (status == 'ToDo' || status == 'To Do') {
      errors.status = 'The task has not been activated on the DevOps board'
      isValid = false;
    }

    if (status == 'Closed') {
      errors.status = 'The ticket has already been closed.'
      isValid = false;
    }

    if (!isValid) {
      setError(errors);
    } else {
      setError({});
    }
    return isValid;
  };

  const [isButtonDisabled, setIsButtonDisabled] = useState(false);


  const serachByName = (e) => {
    setsearchParam(e.target.value);
  };


  var dateFrom = moment().subtract(1, 'months');
  var dateFroms = moment(dateFrom).subtract(1, 'months').endOf('month').format('DD-MM-YYYY');
  var monthValue = dateFrom.format('MM');
  var year = moment().year();
  const finalDate = `${dateFroms}-${monthValue}-${year}`;
  const formattedDate = moment(finalDate, 'DD-MM-YYYY');
  const value = formattedDate.format('YYYY-MM-DD');

  const addTimeLog = async () => {
    const isValid = validateData();
    if (!isValid) {
      return;
    }

    if (!isButtonDisabled) {
      setIsButtonDisabled(true);
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 3000);
      setAddData(true);
    }

    const { timeLogDate } = taskData;
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const itemDate = new Date(timeLogDate);
    if (currentDay >= InputValue && itemDate.getMonth() !== currentDate.getMonth()) {
      toast.error(`Time logs for the previous month can only be added/edited until the ${InputValue} of this month.`);
      setTaskData({
        ticketHours: 0,
        ticketMinutes: 0,
        timeLogDate
      });
      return;
    }

    if (!isEdit) {

      try {

        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}TimeLog/AddTimeLog`,
          taskData
        );

        if (response.status === 200) {
          setTaskData({
            userId: loginData?.oid,
            ticketHours: 0,
            ticketMinutes: 0,
            projectId: project_id,
            workItemId: workitem_id,
            timeLogDate: timeLogDate,
            CreatedBy: loginData?.name,
            ticketState: status,
            userName: loginData?.name
          });
          setAddData(false);
          toast.success("Successfully added");
        }
      } catch (error) {
        console.log(error.response.data);
        toast.error(error.response.data);
      }
      return setEditData(null);
    } else {
      const { userId, projectId, ...newData } = taskData;
      const updatedData = {
        ...newData,
        timeLogId: editData.timeLogId,
        UpdatedBy: loginData?.name,
        projectId: projectId

      };
      try {

        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}TimeLog/UpdateTimeLog`,
          updatedData
        );
        if (response.status === 200) {
          setEditData({
            userId: loginData?.oid,
            ticketHours: 0,
            ticketMinutes: 0,
            projectId: projectId,
            workItemId: workitem_id,
            timeLogDate: moment().format("YYYY-MM-DD"),
            ticketState: status,
          });
          setAddData(false);
          setIsEdit(false);
          toast.success("Successfully updated");

        }
      } catch (error) {
        console.log(error.response.data);
        toast.error(error.response.data);
      }
    }

  };

  const widthPercentage = estimates?.percentage;
  let spent = ((estimates && estimates?.spentMinute) / 60).toString();
  let remaining = ((estimates && estimates?.remainingMinute) / 60).toString();
  let remainingHour = remaining === "0" ? 0 : Number(remaining.split(".")[0]);
  let remainingMinute =
    remaining === "0" ? 0 : (remaining - remainingHour) * 60;
  let spentHour = spent === "0" ? 0 : Number(spent.split(".")[0]);
  let spentMinute = spent === "0" ? 0 : (spent - spentHour) * 60;
  const background = widthPercentage < 100 ? "#d67f3c" : "#E80000";

  const clearSearch = (e) => {
    setSerach(!serach);
    setsearchParam("");
    setLoader(!loader);
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleFilterClick();
    }
  };




  return (
    <div className="p-4 md:p-4">
      <div className="flex gap-1 items-center">
        <div>
          <div className="flex gap-1 items-center">
            <FaRegClock className="text-yellow text-2xl " />
            <label className="text-xl sm:text-sm sm:leading-tight font-semibold">
              Estimated Time: {estimates?.estimatedMinute / 60} hours || Completed :{" "}
              {parseFloat(spentHour).toFixed(2)} hours :{" "}
              {parseFloat(spentMinute).toFixed(2)} minutes || Remaining :{" "}
              {parseFloat(remainingHour).toFixed(2)} hours :{" "}
              {parseFloat(remainingMinute).toFixed(2)} minutes
              {estimates?.estimatedMinute < spentHour * 60 + spentMinute ? (
                <>
                  {" "} || Extra Time:{" "}
                  {Math.floor(((spentHour * 60 + spentMinute) - estimates?.estimatedMinute) / 60)} hours{" "}
                  {Math.floor(((spentHour * 60 + spentMinute) - estimates?.estimatedMinute) % 60)} minutes
                </>
              ) : null}
            </label>
          </div>
        </div>
      </div>
      <div className="mt-4">
        <div className="w-full bg-gray-200 mb-4 dark:bg-gray-700 max-w-[650px] bg-gray  h-6 rounded-sm">
          <div
            className="bg-blue-600  w-full max-w-[650px] h-6 rounded-sm dark:bg-blue-500 "
            style={{ width: `${widthPercentage}%`, background: background }}
          >
            <span className="pl-2 text-white ">{widthPercentage}%</span>
          </div>
        </div>
      </div>
      {error && (
        <p className="text-xs text-red">
          {error && <p className='text-xs text-red'>{error.timeLog || error.timeLogDate || error.workItemType || error.comment || error.status}</p>}
        </p>
      )}
      <div>
        <div className=" mt-3">
          <div className="flex gap-2 items-center  md:flex-wrap">
            <div className="flex gap-1  flex-col items-start filter-bar">
              <label className="text-[14px] font-medium">Hours</label>
              <Input
                type="number"
                name="hours"
                id="hours"
                min="0"
                step="1"
                className="timeTextBox  pl-1 border border-gray text-[12px] w-full rounded-md h-10"
                value={taskData && taskData.ticketHours}
                onChange={(e) =>
                  setTaskData({ ...taskData, ticketHours: e.target.value })
                }
              />
            </div>
            <div className="flex gap-1  flex-col items-start filter-bar">
              <label className="text-[14px] font-medium">Min</label>
              <Input
                type="number"
                name="minutes"
                id="minutes"
                min="0"
                step="15"
                className="timeTextBox w-full pl-1 border border-gray text-[12px] rounded-md h-10"
                value={taskData && taskData.ticketMinutes}
                onChange={(e) =>
                  setTaskData({ ...taskData, ticketMinutes: e.target.value })
                }
              />
            </div>

            <div className="flex gap-1 items-start flex-col filter-bar">
              <label className="text-[14px] font-medium">Date</label>
              <Input
                type="Date"
                name="date"
                min={value}
                id="date"
                className="timeTextBox w-18  pl-1 border border-gray text-[12px] w-full rounded-md h-10"

                value={
                  (taskData && taskData?.timeLogDate?.substring(0, 10)) ||
                  moment().format("DD-MM-YYYY")
                }

                onChange={(e) =>
                  setTaskData({ ...taskData, timeLogDate: e.target.value })
                }
              />
            </div>

            <div className="flex gap-1 items-start flex-col filter-bar">
              <label className="text-[14px] font-medium">Options</label>
              <Select
                id="selectBox"
                className="text-[14px] font-light border md:w-full border-gray rounded-md h-10"
                value={(taskData && taskData.workItemType) || ""}
                selectOptions={selectEnum}
                onChange={(e) =>
                  setTaskData({ ...taskData, workItemType: e.target.value })
                }
              />
            </div>
            <div className="flex gap-1 items-start flex-col flex-grow filter-bar">
              <label className="text-[14px] font-medium">Comment</label>
              <input
                type="text"
                name="comment"
                className="timeTextBox  pl-1 border border-gray text-[12px]  w-full rounded-md h-10"
                value={(taskData && taskData.comment) || ""}
                onChange={(e) =>
                  setTaskData({ ...taskData, comment: e.target.value })
                }
                required
              />
            </div>



            <div>
              <div
                className="flex items-start border border-gray px-4 gap-1 bg-yellow rounded-md mt-6"
              >
                <label className="text-[14px] font-medium"></label>
                <Button
                  label={isEdit ? 'Update' : 'Add'}
                  className="text-[12px] px-4 py-[10px] text-white  font-semibold"
                  disabled={isButtonDisabled}
                  onClick={() => addTimeLog()}
                />
              </div>
            </div>
          </div>
          <div className="flex items-center gap-4 mt-6">
            <div class="w-full max-w-[350px]">
              <div class="relative flex w-full  items-stretch flex-nowrap flex-col">
                <label className="text-[14px] pb-1 font-medium">Search</label>
                <div className="flex items-center relative m-0 -mr-0.5  min-w-0 h-10 flex-auto rounded-l border border-gray rounded-md border-neutral-300 bg-transparent bg-clip-padding px-3 py-[0.25rem] text-base font-normal leading-[1.6] text-neutral-700 outline-none transition duration-200">

                  <input
                    type="input"
                    class="outline-none w-full max-w-[350px]"
                    placeholder="Search by Name"
                    value={searchParam}
                    aria-label="Search"
                    onChange={(event) => serachByName(event)}
                    aria-describedby="button-addon1"
                    onKeyDown={handleKeyDown}
                  />
                  {searchParam && <RxCross2 onClick={(e) => clearSearch(e)} />}

                </div>
                <button
                  class="relative z-[2] hidden h-10  items-center rounded-r bg-yellow px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-primary-700 hover:shadow-lg focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800 active:shadow-lg"
                  type="button"
                  id="button-addon1"
                  data-te-ripple-init
                  onClick={() => handleFilterClick()}
                  data-te-ripple-color="light"
                >
                  <FaSearch className="text-white text-sm" />
                </button>
              </div>
            </div>
            <div className="flex gap-1 items-start flex-col filter-bar w-full max-w-[200px]  sm:w-full">
              <label className="text-[14px] font-medium">Date From</label>
              <Input
                type="Date"

                name="date"

                id="date"

                className="timeTextBox w-18  pl-1 border border-gray text-[12px] w-full rounded-md h-10"

                value={dateData.FromDate}

                onChange={(e) =>
                  setDateData({ ...dateData, 'FromDate': e.target.value })
                }
              />
            </div>

            <div className="flex gap-1 items-start flex-col filter-bar w-full max-w-[200px]  sm:w-full">

              <label className="text-[14px] font-medium">Date To</label>

              <Input

                type="Date"

                name="date"

                id="date"

                className="timeTextBox w-18  pl-1 border border-gray text-[12px] w-full rounded-md h-10"

                value={dateData.ToDate}

                onChange={(e) =>

                  setDateData({ ...dateData, 'ToDate': e.target.value })

                }

              />

            </div>

            <div className="flex gap-3 mt-[21px] w-full max-w-[300px]">

              <Button label={'Search'} class="bg-yellow text-sm hover:bg-blue-700 text-white  py-1 px-4 sm:py-2 rounded  h-[40px] w-full" onClick={() => handleFilterClick()} />

              <Button label={'Reset'} class="text-white text-sm bg-blue min-w-[100px] py-1 sm:py-2 px-4 md:px-2 rounded sm:ml-[1px] h-[40px] w-full" onClick={() => handleFilterClear()} />

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeBar;
