import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import ReportItem from '../../Atoms/ReportItem'
import Sidebar from '../../Molecules/Sidebar';
import { useLocation } from 'react-router-dom';
import { Button, Heading } from '../../Atoms/index';
import TopBar from '../../Molecules/TopBar';
import axios from 'axios';
import AccessPage from '../AccessPage';
import { jwtDecode } from 'jwt-decode';
import { getLocalStorageData } from '../../../constants/user';
import Loader from '../../Atoms/Loader';


const WorkItems = () => {

  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [menus, setMenusData] = useState([]);
  const [loginData, setLoginData] = useState(null);
  const [loader, setLoader] = useState(true);


  const currentUserId = getLocalStorageData("access_token");

  useEffect(() => {
    const token = currentUserId !== null ? jwtDecode(currentUserId) : "";
    setLoginData(token);
  }, [currentUserId]);


  const fetchMenusData = async () => {
    try {

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}AclRecord/GetPermissionMenu?userId=${loginData?.oid}`
      );

      if (response.status == 200) {
        setMenusData(response?.data)
        setLoader(false)

      }
    } catch (error) {
      console.log(error);
      setLoader(false)
    }
  }

  useEffect(() => {
    if (loginData) {
      fetchMenusData()
    }

  }, [loginData])


  const breadcrumbData = [
    {
      name: 'Reports',
      href: '/reports',
    }
  ];


  if (loader) {
    return <div> <Loader text="loading..." /></div>;
  }



  return <>
    <div className='flex  md:mt-0 md:flex-col'>
      <Sidebar sidebarVisible={sidebarVisible} setSidebarVisible={setSidebarVisible} />
      <div className={`flex-grow ${sidebarVisible ? 'w-[85%] lg:w-[75%] md:w-[100%]' : 'w-[93%] lg-w:[91%]'}`}>
        <TopBar breadcrumbData={breadcrumbData} />
        {menus && menus.showReports === true ? <ReportItem /> : <AccessPage />}

      </div>
    </div>
  </>
}

export default WorkItems;