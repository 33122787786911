import PropTypes from 'prop-types';

const Button = ({ type, onClick, label, className, backgroundColor, dataTestId, disabled, ...props }) => {
  return (
    <button
      type={type}
      className={className}
      onClick={onClick}
      data-testid={dataTestId}
      disabled={disabled}
      {...props}
    >
      {label}
    </button>
  );
};
Button.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  type: PropTypes.string,
};
export default Button;
