import React, { useState } from 'react'
import './App.css';
import Timelog from './components/Organisms/TimelogDetails';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TimelogSummary from './components/Organisms/TimelogSummary';
import AppRouter from './Routers/AppRouters';
import { Provider } from 'react-redux';
import store from './store'; // Import your Redux store
import { Router } from 'react-router-dom';
import Calender from './components/Organisms/Calendar';
import Modal from './components/Atoms/Modals';
function App() {
  const [showCalender, setShowCalender] = useState(false);
  const route = Router;
  if (window?.location?.search?.includes('?showCalender=true')) {
    if (!showCalender) {
    setShowCalender(true);
    }
  }

  const onClose = () => {
    
    if (window?.location?.search?.includes('?showCalender=true')) {
      let url = window.location.href;
      window.location.search = '';
      // window.location.href = url;
    }

  }
  return (
    <>
      <Provider store={store}>
        <AppRouter />
        <ToastContainer />
        {
          showCalender && <Modal showCal onClose={onClose}><Calender /></Modal>
        }
      </Provider>
    </>
  );
}


export default App;
