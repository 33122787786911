import { React, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { TfiLayoutListThumb } from "react-icons/tfi";
import { GrDocumentTime } from "react-icons/gr";
import { RiStackLine, RiCalendarTodoFill, RiExchangeBoxLine, RiFileExcel2Line } from "react-icons/ri";
import { TbReport, TbWorldShare } from "react-icons/tb";
import { FaBars, FaSearch } from "react-icons/fa";
import { IoIosArrowForward, IoIosArrowBack } from 'react-icons/io'; // Import the icons
import { useMediaQuery } from "react-responsive";
import LoginButton from "../../Atoms/LoginButton";
import UserName from '../../Atoms/UserName'
import image from '../../../asest/logo-mini-admin.png'
import project from '../../../asest/project (1).png';
import summary from '../../../asest/summary.png';
import devops from '../../../asest/devops.png';
import { useLocation } from 'react-router-dom';
import { FaCalendarAlt } from "react-icons/fa";
import { SlCalender } from "react-icons/sl";
import Calender from "../../Organisms/Calendar";
import { MdAdminPanelSettings } from "react-icons/md";
import axios from "axios";
import { useDispatch } from "react-redux";
import { jwtDecode } from "jwt-decode";
import { getLocalStorageData } from '../../../constants/user';
import { IoSettings } from "react-icons/io5";



const Sidebar = ({ sidebarVisible, setSidebarVisible }) => {
  const isMobile = useMediaQuery({ maxWidth: 880 });
  const [isDivVisible, setIsDivVisible] = useState(!isMobile);
  const location = useLocation();
  const { pathname } = location;
  const [isActive, setIsActive] = useState(false);
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [pageSize, setPagesize] = useState(10);
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [loginData, setLoginData] = useState(null);


  const currentUserId = getLocalStorageData("access_token");

  const dispatch = useDispatch();

  useEffect(() => {
    const token = currentUserId !== null ? jwtDecode(currentUserId) : "";
    setLoginData(token);
  }, [currentUserId]);





  const showSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  }
  const sidetoggle = () => {
    setIsActive(!isActive);
  }

  const fetchData = async () => {
    try {

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}AclRecord/GetPermissionMenu?userId=${loginData?.oid}`
      );

      if (response.status == 200) {
        setData(response?.data)

      }
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    if (loginData) {
      setLoader(true);
      fetchData();
      setLoader(false);
    }
  }, [loginData]);


  return (
    <>
      <div className={`bg-black  text-center flex items-start h-screen md:h-auto  sticky md:z-40 sm:z-10 top-[0px]   w-[25%] overflow-y-auto transition-all sidebar shadow-[3px_0_3px_#888]   ${sidebarVisible && "show-full"}`}>
        <div className="w-full">
          <div className={`flex items-center justify-around ${!sidebarVisible && 'pl-3'} bg-black h-[64px] gap-1 sm:flex-row-reverse`}>
            {
              sidebarVisible &&
              <div
                className={`w-full px-6 py-8 sidebar-logo bg-darkblue md:flex md:justify-end md:gap-4 transition-width duration-1000 ease-in-out`}
              >                <FaSearch className="hidden text-white text-right text-xl " />

              </div>
            }

            {
              !sidebarVisible &&
              <img
                src={image}
                className="bg-darkblue"
                alt="images"
              />
            }

            <div className={`text-3xl cursor-pointer text-white pt-2`} onClick={isMobile ? sidetoggle : showSidebar} >
              {isMobile ? <FaBars className="pl-2 sm:block md:hidden" /> : (sidebarVisible ? <IoIosArrowBack /> : <IoIosArrowForward />)}
            </div>
          </div>

          {(isMobile || (!isDivVisible || !isMobile)) && (isDivVisible || isMobile) && (
            <>
              <div className={isActive ? 'active' : 'slidetoogle'}>
                <div className='hidden sm:flex sm:justify-between items-center px-2'>
                  <UserName />
                  <div className="flex gap-1 items-center">
                    <LoginButton />
                    <span className="text-sm">Logout </span>
                  </div>
                </div>
                <ul className="flex flex-col justify-center sm:block  sm:bg-white sm:w-full">

                  {data?.showProjects && <li className={`pr-0 p-4 flex items-center gap-2 justify-left  ${!sidebarVisible && 'justify-center pr-4'}  ${pathname.includes('/projects') && 'darkgray sm:text-white'}`}>

                    <Link to="/projects">

                      <RiStackLine className="text-blue text-4xl" />
                    </Link>
                    {
                      sidebarVisible &&
                      <Link to="/projects" className={`text-md sm:text-black text-left text-white border-gray font-semibold ${!pathname.includes('/projects') && 'sm:text-white '} `}>
                        Projects
                      </Link>
                    }

                  </li>}

                  {data?.showTimeLogSummary && <li className={`p-4  pr-0 flex items-center gap-2 justify-left ${!sidebarVisible && 'justify-center pr-4'}  ${pathname.includes('/timelogsummary') && 'darkgray text-white'}`}>
                    <Link to="/timelogsummary">

                      <GrDocumentTime className="text-blue text-4xl document-type" />
                    </Link>
                    {
                      sidebarVisible &&
                      <Link
                        to="/timelogsummary"
                        className={`text-md text-white  text-left border-gray font-semibold ${!pathname.includes('/timelogsummary') && 'text-white sm:text-black'} `}
                      >
                        Timelog Summary
                      </Link>
                    }
                  </li>}



                  {data?.showProjects && <li className={`pr-0 p-4 flex items-center gap-2 justify-left ${!sidebarVisible && 'justify-center pr-4'} `}>
                    <Link target="_blank" to="http://devops.tglserver.net/TMotions/">
                      <TbWorldShare className="text-blue text-4xl" />
                    </Link>
                    {
                      sidebarVisible &&
                      <Link target="_blank" to="http://devops.tglserver.net/TMotions/" className="text-md text-white text-left sm:text-black border-gray font-semibold">
                        View DevOps
                      </Link>
                    }
                  </li>}

                  {data?.showCalendar && <li className={`pr-0 p-4 flex items-center gap-2 justify-left ${!sidebarVisible && 'justify-center pr-4'} `}>
                    <Link target="_blank" to="http://devops.tglserver.net/TMotions/">
                      <RiCalendarTodoFill className="text-blue text-4xl" />
                    </Link>
                    {
                      sidebarVisible &&
                      <Link target="_blank" to="/calendar" className="text-md text-white text-left sm:text-black border-gray font-semibold">
                        Holiday Calendar
                      </Link>
                    }
                  </li>}

                  {data?.showManageAcl && <li className={`p-4  pr-0 flex items-center gap-2 justify-left ${!sidebarVisible && 'justify-center pr-4'}  ${pathname.includes('/access-control') && 'darkgray text-white'}`}>
                    <Link to="/access-control">

                      <MdAdminPanelSettings className="text-blue text-4xl document-type" />
                    </Link>
                    {
                      sidebarVisible &&
                      <Link
                        to="/access-control"
                        className={`text-md text-white  text-left border-gray font-semibold ${!pathname.includes('/access-control') && 'text-white sm:text-black'} `}
                      >
                        Access Control List
                      </Link>
                    }
                  </li>}

                  {data?.showMoveTimeLog && <li className={`p-4  pr-0 flex items-center gap-2 justify-left ${!sidebarVisible && 'justify-center pr-4'}  ${pathname.includes('/movetimelog') && 'darkgray text-white'}`}>
                    <Link to="/movetimelog">

                      <RiExchangeBoxLine className="text-blue text-4xl document-type" />
                    </Link>
                    {
                      sidebarVisible &&
                      <Link
                        to="/movetimelog"
                        className={`text-md text-white  text-left border-gray font-semibold ${!pathname.includes('/movetimelog') && 'text-white sm:text-black'} `}
                      >
                        MoveTimeLog
                      </Link>
                    }
                  </li>}

                  {data?.showReports && <li className={`p-4  pr-0 flex items-center gap-2 justify-left ${!sidebarVisible && 'justify-center pr-4'}  ${pathname.includes('/reports') && 'darkgray text-white'}`}>
                    <Link to="/reports">

                      <RiFileExcel2Line className="text-blue text-4xl document-type" />
                    </Link>
                    {
                      sidebarVisible &&
                      <Link
                        to="/reports"
                        className={`text-md text-white  text-left border-gray font-semibold ${!pathname.includes('/reports') && 'text-white sm:text-black'} `}
                      >
                        Reports
                      </Link>
                    }
                  </li>}
                  {data?.showSettings && <li className={`p-4  pr-0 flex items-center gap-2 justify-left ${!sidebarVisible && 'justify-center pr-4'}  ${pathname.includes('/TimelogSetting') && 'darkgray text-white'}`}>
                    <Link to="/timelogsetting">

                      
                      <IoSettings className='text-blue text-4xl document-type ' />
                    </Link>
                    {
                      sidebarVisible &&
                      <Link
                        to="/timelogsetting"
                        className={`text-md text-white  text-left border-gray font-semibold ${!pathname.includes('/TimelogSetting') && 'text-white sm:text-black'} `}
                      >
                       Setting
                      </Link>
                    }
                  </li>}

                </ul>
              </div>

            </>

          )}
        </div>

      </div>

    </>

  );
};

export default Sidebar;