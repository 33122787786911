import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import  filter  from './workItem';


const reducer = combineReducers({
    filter
});

const store = configureStore({
  reducer,
});

export default store;
