import PropTypes from 'prop-types';
const Input = ({
    type,
    name,
    id,
    min,
    step,
    defaultValue,
    className,
    placeholder,
    onChange,
    disabled,
    value,
    autoFocus,
    checked,
    dataTestId = 'input',
    ...otherProps
}) => {
    return (
        <>
            <input
                defaultValue={defaultValue}
                name={name}
                type={type}
                id={id}
                min={min}
                step={step}
                className={className}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                disabled={disabled}
                autoFocus={autoFocus}
                {...otherProps}
                data-testid={dataTestId}
                checked={checked}
               
            />
        </>
    );
};
Input.propTypes = {
    type: PropTypes.string.isRequired,
    className: PropTypes.string,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    name: PropTypes.string,
    disabled: PropTypes.bool,
};
export default Input;
