import Button from '../Button/index';
import { RxCross1 } from 'react-icons/rx';
import PropTypes from 'prop-types';
import Heading from '../Heading';


const Modal = ({ isOpen, onClose, onDelete, children, showCal, isAccess }) => {
    if (!isOpen && !showCal) {
        return null;
    } if (showCal && !isOpen) {
        return (
            <>
                <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-opacity-75 z-999 ">
                    <div className="w-full max-w-md p-6 rounded shadow-lg bg-white">
                        <span className="float-right cursor-pointer text-gray-600 text-2xl" onClick={onClose}>&times;</span>
                        {children}
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-opacity-75 z-999 ">
                    <div className="w-full max-w-md p-6 rounded shadow-lg bg-white">
                        <span className="float-right cursor-pointer text-gray-600 text-2xl" onClick={onClose}>&times;</span>
                        <h2 className="text-xl mb-4">Confirmation</h2>
                        <p className="mb-6">Are you sure you want to delete this {isAccess ? 'access' : 'timelog entry'}?</p>
                        <div className="flex justify-end">
                            <button onClick={onDelete} className="px-4 py-2 bg-red-500 text-gray-700 rounded mr-2">Ok</button>
                            <button onClick={onClose} className="px-4 py-2 bg-gray-300 text-gray-700 rounded">Cancel</button>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Modal;
