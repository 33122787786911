import React from "react";
import { Link } from "react-router-dom";
import moment from "moment";
import { isMobile } from "../../../constants/user";
import { useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { FaBug } from "react-icons/fa6";
import { SlChemistry } from "react-icons/sl";
import { SiVlcmediaplayer } from "react-icons/si"
import { BiTask, BiSolidUserCircle, BiDotsHorizontal } from 'react-icons/bi';
import { GoDotFill } from 'react-icons/go'
import { FaRegClock, FaCrown, FaMinus,FaTrophy } from "react-icons/fa";
import { GiOpenBook } from "react-icons/gi";

const TimelogTable = ({ data }) => {
  const [hoveredItem, setHoveredItem] = useState(null);
  const _isMobile = isMobile();
  const [mobileData, setMobileData] = useState(data);

  const toggleview = (projectIndex) => {
    const updatedData = [...mobileData];
    updatedData[projectIndex].toggle = !updatedData[projectIndex].toggle;
    setMobileData(updatedData);
  };

  const Addition = (value, index) => {
    // Your function logic here
    let totalSumHour = 0;
    let totalSumMinute = 0;
    const arr = value;
    arr.map((item) => {
      totalSumHour += parseInt(item?.ticketHours);
      totalSumMinute += parseInt(item?.ticketMinutes);
    });
    totalSumHour += Math.floor(totalSumMinute / 60);
    totalSumMinute = totalSumMinute % 60;

    return `${totalSumHour}:${totalSumMinute}`;
  };

  // Extract unique dates from the data
  const dates = [
    ...new Set(
      data?.flatMap((item) =>
        item.project.flatMap((project) =>
          project.parentList.flatMap((parent) =>
            parent.workList.flatMap((work) =>
              work.details.map((detail) => detail.timeLogDate)
            )
          )
        )
      )
    ),
  ];

  // Sort dates
  dates.sort((a, b) => new Date(a) - new Date(b));

  const formatDate = dates.map((date) => moment(date).format("Do MMM, ddd"));

  // Generate table header
  const header = ["Project", "Parent", "Id", "Workitem", 'Type', ...formatDate, "Total"];

  let row = [];

  const convertHoursAndMinutesToHoursFormat = (hours, minutes) => {
    let totalHours = Number(hours);
    totalHours += Number(minutes) / 60;
    let formattedHours = Math.floor(totalHours);
    let remainingMinutes = Math.round((totalHours % 1) * 60); // Rounding to nearest integer
    return `${formattedHours}h ${remainingMinutes}m`;
  };

  const getTotalHours = (details) => {
    let hours = 0;
    let minutes = 0;
    details.map((detail) => {
      hours += detail.ticketHours;
      minutes += detail.ticketMinutes;
      return detail;
    });
    return `${hours},${minutes}`;
  };

  data.forEach((item) => {
    item.project.forEach((project) => {
      project.parentList.forEach((parent, pindex) => {
        parent.workList.forEach((work) => {
          row.push({
            projectName: item.projectName,
            parentName: project.parent || "",
            workItemName: parent.workItem,
            workItemType: work.workItem,
            workItemId: work.workItemId,
            workItemLink: `/timelog/${work.details[0].projectId}/${work.details[0].workItemId}`,
            timeLog: work.details.map((detail) => ({
              date: detail.timeLogDate,
              time: `${detail.ticketHours}h ${detail.ticketMinutes}m`,
              hours: detail.ticketHours,
              mins: detail.ticketMinutes,
            })),
            projectSpan: 0,
            workItemSpan: project.parentList.length,
            totalHours: getTotalHours(work.details),
          });
        });
      });
    });
  });

  row = row.map((item) => {
    item.projectSpan = row.filter(function (current) {
      return current.projectName === item.projectName;
    }).length;

    // item.workItemSpan = row.filter(function (current) {
    //   return (current.parentName === item.parentName);
    // }).length;

    return item;
  });

  const generateRowTotal = () => {
    let hours = 0;
    let minutes = 0;
    dates.map((date) => {
      row.map((detail) => {
        const matchedDate = detail.timeLog.filter(
          (time) => date === time.date
        );
        if (matchedDate.length) {
          [hours, minutes] = hourMinuteSum(matchedDate, hours, minutes)
        }
        return detail;
      });
      return date;
    })

    return convertHoursAndMinutesToHoursFormat(hours, minutes);
  };

  const sameDateSum = (match) => {
    let totalHours = 0;
    let totalMinutes = 0;

    match.forEach(entry => {
      totalHours += entry.hours;
      totalMinutes += entry.mins;
    });

    return convertHoursAndMinutesToHoursFormat(totalHours, totalMinutes)
  }

  const hourMinuteSum = (matchDate, hours, minutes) => {
    matchDate.forEach((item) => {
      hours = hours + item.hours
      minutes = minutes + item.mins
    }
    )
    return [hours, minutes]
  }

  
  const workItemicon = (title) => {
    if (title == 'User Story') {
      return <GiOpenBook title = 'User Story' className='text-blue' />
    }
    else if (title == 'Task') {
      return <BiTask title = 'Task' className='text-yellow' />
    }
    else if (title == 'Subtask') {
      return <BiTask title="subtask" className='text-[#E5C313]' />
    }
    else if (title == 'Test Case') {
      return <SlChemistry title = 'Test Case' className='text-[#3bc24d]' />
    }
    else if (title == 'Feature') {
      return <FaTrophy title = 'Feature' className='text-[#9b55eb]' />
    }
    else if (title == 'Epic') {
      return <FaCrown title ='Epic'  className='text-[#f3a736]' />
    }
    else if (title == 'Issue') {
      return <SiVlcmediaplayer title='Issue' className='text-[#b600a0]' />
    }
    else {
      return <FaBug title='Bug' className='text-yellow'/>
    }
  }

 
  const handleMouseOver = (type) => {
    let task, userStory, epic;
    switch (type) {
      case 'Task':
        task = 'Task';
        userStory = null;
        epic = null;
        break;
      case 'User Story':
        task = null;
        userStory = 'User Story';
        epic = null;
        break;
      case 'Epic':
        task = null;
        userStory = null;
        epic = 'Epic';
        break;
      default:
        task = null;
        userStory = null;
        epic = null;
        break;
    }
    setHoveredItem({ task, userStory, epic });
  };


  return data?.length > 0 ? (
    <div className="mt-2">
      {_isMobile ? (
        <div>
          <div>
            <h1 className="text-sm pb-1">Overall Time</h1>
            <div className="bg-secbg px-1 py-2 rounded-sm flex justify-between">
              <span className="text-[13px]">Total</span>
              <span className="text-[13px]">{generateRowTotal()}</span>
            </div>
          </div>
          {mobileData?.map((items, projectIndex) => (
            <div>
              <div
                className={
                  items?.toggle
                    ? "flex items-center justify-between py-3 toggle-bar "
                    : "flex items-center justify-between py-3 toggle-bar"
                }
              >
                <span
                  className={
                    items?.toggle
                      ? "hidden "
                      : "text-[13px] bg-secbg w-24 px-1 py-2 rounded-sm Project-name"
                  }
                >
                  Project
                </span>
                <div
                  className={
                    items?.toggle
                      ? "bg-mobileBar w-full rounded-sm text-white py-2 px-1 flex items-center justify-between"
                      : "bg-lightgray w-full rounded-sm px-1 py-[7px] flex justify-between items-center"
                  }
                >
                  <span className="text-[13px]">{items.projectName}</span>
                  <span
                    onClick={() => toggleview(projectIndex)}
                    className="text-lg"
                  >
                 
                    {items?.toggle ? (
                      <FaMinus className="text-sm" />
                    ) : (
                      <FaPlus className="text-sm" />
                    )}
                  </span>
                </div>
              </div>
              {items?.toggle && (
                <>
                  <div>
                    {items.project.map((item, innerProjectIndex) => {
                      
                      const workItemURL = `/timelog/${item?.parentList?.[0]?.workList?.[0]?.details?.[0]?.projectId}/${item?.parentList?.[0]?.workList?.[0]?.workItemId}`;
                      return (
                       
                        <>
                        
                          <div className="flex items-center justify-between  border border-b border-white">
                            <span className="text-[13px] bg-secbg w-24 px-1 py-2">
                              Parent
                            </span>
                            <div className="bg-lightgray w-full rounded-sm px-1 py-[8px] flex justify-between items-center">
                              <span className="text-sm">
                                {item?.parent ? item?.parent : "--"}
                              </span>
                            </div>
                          </div>
                          <div className="flex items-center justify-between  border border-b border-white">
                            <span className="text-[13px] bg-secbg w-24 px-1 py-2">
                              Work Item
                            </span>

                            <div className="bg-lightgray w-full rounded-sm px-1 py-[8px] flex justify-between items-center">
                              <Link to={workItemURL} className="text-sm truncate sm:w-32">
                                {item?.parentList[0].workItem}
                              </Link>
                            </div>
                          </div>

                          {item?.parentList[0]?.workList.map(
                            (value, listIndex) => {
                              return value?.details?.map((vals, valIndex) => {
                                return (
                                  <>
                                    <div className="flex items-center justify-between  border border-b border-white">
                                      <span className="text-[13px] bg-secbg w-24 px-1 py-2">
                                        {moment(vals.timeLogDate).format(
                                          "Do MMM"
                                        )}
                                      </span>
                                      <div className="bg-lightgray w-full rounded-sm px-1 py-[8px] flex justify-between items-center">
                                        <span
                                          key={valIndex}
                                          className="text-sm"
                                        >{`${vals.ticketHours}:${vals.ticketMinutes}`}</span>
                                      </div>
                                    </div>

                                    {valIndex ===
                                      value?.details?.length - 1 && (
                                        <div className="flex items-center justify-between  border border-b border-white">
                                          <span className="text-[13px] bg-secbg w-24 px-1 py-2">
                                            Total
                                          </span>
                                          <div className="bg-lightgray w-full rounded-sm px-1 py-[8px] flex justify-between items-center">
                                            <span
                                              key={valIndex}
                                              className="text-sm"
                                            >
                                              {Addition(
                                                value?.details,
                                                listIndex
                                              )}
                                            </span>

                                          </div>

                                        </div>
                                      )}
                                  </>
                                );
                              });
                            }
                          )}
                        </>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          ))}
        </div>
      ) : (
        <div className="overflow-auto block">
          <table className="border border-gray">
            <thead className="bg-secbg p-1 ">
              <tr>
                {header?.map((item) => (
                  <th className="p-2 border-gray border text-[10px] whitespace-nowrap">{item}</th>
                ))}
              </tr>
            </thead>
         
            <tbody className="p-4 border border-gray border-separate text-[12px]">
              {row.map((item, index) => {
                const renderProjectName =
                  row[index - 1]?.projectName !== item.projectName;
                const renderParentName =
                  (row[index - 1]?.parentName !== item.parentName || renderProjectName);
                const renderWorkItemName =
                  (row[index - 1]?.workItemName !== item.workItemName || renderParentName);;
                return (
                  
                  <tr>
                  
                    {renderProjectName ? (
                      <td
                        className="border-gray border px-2 whitespace-nowrap overflow-hidden"
                        rowSpan={item.projectSpan}
                      >
                        {item.projectName}
                      </td>
                    ) : null}
                    {renderParentName ? (
                      <td
                        className="border-gray border px-2 whitespace-nowrap overflow-hidden"
                        rowSpan={item.workItemSpan}
                      >
                        {item.parentName}
                      </td>
                    ) : null}
                     {renderWorkItemName ? (
                    
                      
                    
                    <td
                      className="border-gray border px-2 whitespace-nowrap"
                    // rowSpan={item.workItemSpan}
                    >
                     
                      
                    <div className="flex gap-2 items-center text-center">

                      <Link  className="underline whitespace-nowrap overflow-hidden text-ellipsis block w-[90px] hover:whitespace-normal" alt={item.workItemName} to={item.workItemLink} >
                        
                        {item.workItemId}

                      </Link>
                    </div>
                        
                    </td>
                  ) : null}
                    {renderWorkItemName ? (
                    
                      
                     
                      <td
                        className="border-gray border px-2 whitespace-nowrap"
                      // rowSpan={item.workItemSpan}
                      >
                      <div className="flex gap-2 items-center">
                      <h2 onMouseOver={() => handleMouseOver(item.type)} title={hoveredItem && `${hoveredItem.task || hoveredItem.userStory || hoveredItem.epic}`}>{workItemicon(item.workItemType)}</h2>
                        <Link  className="underline whitespace-nowrap overflow-hidden text-ellipsis block w-[90px] hover:whitespace-normal" alt={item.workItemName} to={item.workItemLink} >
                          
                          {item.workItemName}

                        </Link>
                      </div>
                          
                      </td>
                    ) : null}
                    <td className="border-gray border px-2 whitespace-nowrap overflow-hidden">{item.workItemType}</td>
                    {dates?.map((date) => {
                      const matchedDate = item.timeLog.filter(
                        (time) => date === time.date
                      );
                      return matchedDate.length ? (
                        <td className="border-gray border px-2 whitespace-nowrap">
                          {sameDateSum(matchedDate)}
                        </td>
                      ) : (
                        <td className="border-gray border px-2  whitespace-nowrap overflow-hidden">--</td>
                      );
                    })}
                    <td className="border-gray border p-2 font-bold">
                      {convertHoursAndMinutesToHoursFormat(
                        Number(item.totalHours.split(",")[0]),
                        Number(item.totalHours.split(",")[1])
                      )}
                    </td>

                  </tr>

                );
              })}

              <tr className="bg-secbg">
                <td className="font-bold text-center border-gray border p-3" colSpan={5}>
                  Total
                </td>
                {dates?.map((date) => {
                  let hours = 0;
                  let minutes = 0;
                  row.map((detail) => {
                    const matchedDate = detail.timeLog.filter(
                      (time) => date === time.date
                    );
                    if (matchedDate.length) {
                      [hours, minutes] = hourMinuteSum(matchedDate, hours, minutes)
                    }
                    return detail;
                  });

                  const totalHours = convertHoursAndMinutesToHoursFormat(
                    hours,
                    minutes
                  );

                  return (
                    <td className="border-gray border p-3 whitespace-nowrap font-bold">{totalHours}</td>
                  );
                })}
                <td className="border-gray border p-3 whitespace-nowrap font-bold">{generateRowTotal()}</td>
              </tr>
            </tbody>
          </table>
        </div>
      )}
    </div>
  ) : (
    <div className="mt-6 text-lg">No Record Found</div>
  );
};

export default TimelogTable;