const Select = ({
    id,
    className,
    value,
    selectOptions,
    onChange
}) => {
    return (
        <>
            <select
                id={id}
                className={className}
                value={value}
                onChange={onChange}
            >
                <option>{'Select Option'}</option>
                {selectOptions?.map((item) => {
                    return <option value={item.enum} selected={item.enum === value}>{item.enum}</option>
                }
                )}
            </select>
        </>
    );
};

export default Select;
