import React, { useState, useEffect } from 'react'
import WorkItem from '../../Atoms/WorkItem'
import Sidebar from '../../Molecules/Sidebar';
import { useLocation } from 'react-router-dom';
import { Button, Heading } from '../../Atoms/index';
import TopBar from '../../Molecules/TopBar';
import { Link, useParams } from 'react-router-dom';


const WorkItems = () => {
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const receivedData = useParams();
  const { project_id } = receivedData;
  const breadcrumbData = [
    {
      name: 'Projects',
      href: '/projects',
    },
    {
      name: 'Work Item',

    },
  ];


  return <>
    <div className='flex md:mt-0 md:flex-col'>
      <Sidebar sidebarVisible={sidebarVisible} setSidebarVisible={setSidebarVisible} />

      <div className={`flex-grow ${sidebarVisible ? 'w-[85%] lg:w-[75%] md:w-[100%]' : 'w-[93%] lg-w:[91%]'}`}>
        <TopBar breadcrumbData={breadcrumbData} />
        <WorkItem projectId={project_id} />
      </div>
    </div>
  </>
}

export default WorkItems;