import React, { useState, useEffect } from "react";
import Select from 'react-select';
import Button from '../../Atoms/Button'
import { Link, useLocation } from 'react-router-dom';
import { FaLongArrowAltLeft } from "react-icons/fa";
import axios from "axios";
import { getLocalStorageData, setLocalStorageData } from '../../../constants/user';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router';
import { jwtDecode } from "jwt-decode";
import Loader from "../../Atoms/Loader";

function AddAccessList() {
  const [adUsers, setAdusers] = useState([]);
  const [userId, setUserId] = useState();
  const refresh_token = getLocalStorageData('refresh_token');
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedIds, setSelectedIds] = useState(null);
  const [editData, setEditData] = useState({});
  const [accessData, setAccessData] = useState({
    userId: '',
    selectedModuleIds: [],
    userEmail: '',
  });

  const [loginData, setLoginData] = useState(null);
  const [loader, setLoader] = useState(true);

  const editUserId = new URLSearchParams(useLocation().search).get('userId');

  const currentUserId = getLocalStorageData("access_token");
  const navigate = useNavigate();

  useEffect(() => {
    const token = currentUserId !== null ? jwtDecode(currentUserId) : "";
    setLoginData(token);
  }, [currentUserId]);

  const fetchADUsers = async () => {
    try {
      const exptime = getLocalStorageData('expiredTime');

      if (exptime < new Date().toISOString()) {
        try {
          const response = await axios.post(`${process.env.REACT_APP_BASE_URL}Login/GetRefreshToken`, { refreshToken: refresh_token });
          const { access_token, expires_in } = response?.data?.result;
          setLocalStorageData(
            new Date(new Date().getTime() + expires_in * 1000),
            "expiredTime"
          );
          setLocalStorageData(
            access_token,
            "access_token"
          );
          setUserId(access_token);
        } catch (error) {
          console.error('Error fetching refresh token:', error);
        }
      }

      const token = userId || getLocalStorageData('access_token');
      const adresponse = await axios.post(`${process.env.REACT_APP_BASE_URL}TimeLog/GetAllADUsers`, { token });
      setAdusers(adresponse?.data.value);

      if (editUserId) {
        const editResponseData = await axios.get(`${process.env.REACT_APP_BASE_URL}AclRecord/GetUserPermissions?UserId=${editUserId}`,);
        setEditData(editResponseData?.data);
        setLoader(false);
      }


    } catch (error) {
      console.log('Error fetching AD users:', error);
    }
  };

  useEffect(() => {
    if (loginData) {
      fetchADUsers();
    }
  }, [loginData]);

  const handleSelectChange = (event) => {
    const selectedEmail = event.target.value;
    const selectedUser = adUsers.find(user => user.mail === selectedEmail);
    setSelectedUser(selectedUser);
  };


  const handleAccessChange = (selectedOptions) => {
    setSelectedIds(selectedOptions);
  };


  const initialOptions = [
    { label: 'Reports', value: 20, checked: false },
    { label: 'MoveTimeLog', value: 10, checked: false },
    { label: 'ManageAcl', value: 30, checked: false },
    { label: 'Setting', value: 60, checked: false }

  ];

  const CheckboxOption = ({ innerProps, label, isSelected }) => (
    <div {...innerProps} style={{ cursor: 'pointer' }}>
      <input type="checkbox" checked={isSelected} readOnly className="mr-2" />
      <label>{label}</label> 
    </div>
  );


  const handleSubmit = async (event) => {
    event.preventDefault();

    if (editUserId) {
      if (!editData?.moduleIds) {
        toast.error('Select Access Lists');
        return;
      }

      const accessModuleData = accessModules?.map((x) => x.value);

      const payloadData = {
        userId: editData?.userId,
        selectedModuleIds: accessModuleData,
        userEmail: editData?.userEmail,
        userName: editData?.userName,
        CurrentUserId: loginData?.oid
      };

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}AclRecord/UpdateAclRecord`,
          payloadData
        );

        if (response.status === 200) {
          toast.success("Successfully updated");
          navigate('/access-control');
        }
      } catch (error) {
        console.error(error);
        if (error?.response?.status === 400) {
          toast.error('Access is already provided');
        }
      }
    } else {
      if (!selectedUser?.mail) {
        toast.error('Select Email');
        return;
      } else if (!selectedIds) {
        toast.error('Select Access Lists');
        return;
      }

      const accessModuleData = selectedIds?.map((x) => x.value);

      const payloadData = {
        userId: selectedUser?.id,
        selectedModuleIds: accessModuleData,
        userEmail: selectedUser?.mail,
        userName: selectedUser?.displayName,
        CurrentUserId: loginData?.oid
      };

      try {
        const response = await axios.post(
          `${process.env.REACT_APP_BASE_URL}AclRecord/AddAclRecord`,
          payloadData
        );

        if (response.status === 200) {
          setAccessData({
            userId: selectedUser?.id,
            selectedModuleIds: accessModuleData,
            userEmail: selectedUser?.mail,
          });
          toast.success("Successfully added");
          clearFilter();
          navigate('/access-control');
        }
      } catch (error) {
        console.error(error);
        if (error?.response?.status === 400) {
          toast.error('Access is already provided');
          clearFilter();
        }
      }
    }

    setAccessData(null);
  };



  const clearFilter = () => {
    setSelectedUser(null);
    setSelectedIds([]);
    setAccessData({
      userId: '',
      selectedModuleIds: [],
      userEmail: '',
    })

  };

  const accessModules = selectedIds ?
    selectedIds :
    (editData?.moduleIds ?
      editData.moduleIds.map(data => ({
        value: data,
        label: initialOptions.find(option => option.value === data)?.label || data
      })) :
      []
    )

    

  return (
    <div className='p-6 rounded  bg-white'>
      <div className='flex gap-5 items-center pb-2'>
        <h1 className='text-lg font-bold'>Add New</h1>
        <Link className='text-sm flex gap-2 items-center' to='/access-control'><FaLongArrowAltLeft /> <span >Back to Access Control List</span></Link>
      </div>

      <div className="w-full border p-4 border-gray">

        {editUserId ? loader && <Loader text="Data Fetching......" /> : ''}
        <div className='flex gap-4 pt-10'>
          <div className='w-full'>
            <label className='text-[12px] pb-1'>Email</label>
            <select
              placeholder="Select email"
              className="basic-multi-select  border text-[14px] w-full h-[38px] rounded-[4px] border-gray text-black focus:outline-none pl-2 pr-2 "
              value={selectedUser ? selectedUser.mail : editData?.userEmail}
              onChange={handleSelectChange}
              name={'userEmail'}
            >
              <option value={''}>Select email</option>
              {adUsers?.map((option, index) => (
                <option key={index} value={option.mail} >
                  {option.mail}
                </option>
              ))}
            </select>
          </div>
          <div className='w-full'>
            <label className='text-[12px] pb-1'>Name</label>
            <input type="search" placeholder='Name' name="userName" value={selectedUser ? selectedUser.displayName : editData?.userName} className='border text-[12px] w-full h-[38px] rounded-[4px] border-gray text-black focus:outline-none pl-2 pr-2' readOnly />
          </div>

          <div className='w-full'>
            <label className='text-[12px] pb-1'>Access Lists</label>
            <Select
              isMulti
              name={'accessControl'}
              placeholder="Select options"
              className="basic-multi-select  text-[12px]"
              classNamePrefix="select"
              closeMenuOnSelect={false}
              hideSelectedOptions={true}
              components={{ Option: CheckboxOption }}
              value={accessModules}
              onChange={handleAccessChange}
              options={initialOptions?.map(data => ({ value: data?.value, label: data?.label }))}
            />
          </div>
        </div>
        <div className="flex justify-end pt-8 gap-3">
          <Button label={editUserId ? 'Update' : 'Save'} className={`bg-yellow hover:bg-blue-700 text-white text-sm h-[35px]  w-[100px] font-medium py-1 px-1 rounded pt-1 `} onClick={handleSubmit} />
          <Button label={'Cancel'} class="text-white text-sm bg-blue min-w-[100px] py-1 sm:py-2 px-4 md:px-2 rounded sm:ml-[1px]   h-[35px]" onClick={clearFilter} />
        </div>
      </div>
    </div >
  )
}

export default AddAccessList