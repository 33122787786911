import React, { useState, useEffect } from "react";
import { FiFilter } from 'react-icons/fi'
import { BsChevronDown } from 'react-icons/bs'
import { RxCross1 } from 'react-icons/rx'
import Button from '../Button'
import axios from "axios";
import Select from 'react-select';
import { useLocation, useNavigate } from 'react-router-dom';
import { getLocalStorageData, setLocalStorageData } from '../../../constants/user';
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import AccessPage from "../../Organisms/AccessPage";


const text = [
    { label: 'Type', icon: <BsChevronDown /> },
    { label: 'Assigned to', icon: <BsChevronDown /> },
    { label: 'Area', icon: <BsChevronDown /> },
    { label: 'Tags', icon: <BsChevronDown /> }
];


const ReportFilter = ({ reportFilter, setReportFilter, setData, exportToExcel, searchButton, setSearchButton,projectData,userData }) => {
    const [project, setProject] = useState([]);
    const [error, setError] = useState({});
    const [isContentVisible, setIsContentVisible] = useState(false);
    const [projectUser, setProjectUser] = useState([]);
    const [adUsers, setAdusers] = useState([]);
    const [userId, setUserId] = useState();
    const refresh_token = getLocalStorageData('refresh_token');


    const fetchADUsers = async () => {
        try {
            const exptime = getLocalStorageData('expiredTime');

            if (exptime < new Date().toISOString()) {
                try {
                    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}Login/GetRefreshToken`, { refreshToken: refresh_token });
                    const { access_token, expires_in } = response?.data?.result;
                    setLocalStorageData(
                        new Date(new Date().getTime() + expires_in * 1000),
                        "expiredTime"
                    );
                    setLocalStorageData(
                        access_token,
                        "access_token"
                    );
                    setUserId(getLocalStorageData('access_token'))
                    const adresponse = await axios.post(`${process.env.REACT_APP_BASE_URL}TimeLog/GetAllADUsers`, { token: access_token });
                    setAdusers(adresponse.data.value);
                    
                } catch (error) {
                    console.error('Error fetching refresh token:', error);
                }
            }
            else {
                const response = await axios.post(`${process.env.REACT_APP_BASE_URL}TimeLog/GetAllADUsers`, { token: userId });
                setAdusers(response.data.value);
                userData(response.data.value);
            }

        } catch (error) {
            console.log(error);
        }
    };


    const fetchProjectData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}TimeLog/GetAllProjects`);
            setProject(response.data.value);
            projectData(response.data.value)
        } catch (error) {
            console.log(error);
        }
    };

    const fetchProjectUsers = async () => {
        try {
            const { ProjectId } = reportFilter;
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}TimeLog/GetAllUsersByProject?ProjectId=${ProjectId}`);
            setProjectUser(response?.data?.value);
            
        } catch (error) {
            console.log(error);
        }
    };


    const toggleContentVisibility = () => {
        setIsContentVisible(!isContentVisible);
    }

    useEffect(() => {
        setUserId(getLocalStorageData('access_token'));
        fetchProjectData();
    }, [])


    useEffect(() => {
        if (userId) {
            fetchADUsers();
        }
    }, [userId])



    useEffect(() => {
        const { ProjectId } = reportFilter;
        const emptyState = () => {
            setProjectUser([]);
            setReportFilter({ ...reportFilter, UserId: '' });
        };

        if (ProjectId) {
            emptyState();
            fetchProjectUsers();
        } else {
            fetchADUsers();
            emptyState();
            setAdusers([]);
        }
    }, [reportFilter?.ProjectId]);

    const validateData = () => {
        const errors = {};
        let isValid = true;

        // if (!reportFilter.ProjectId) {
        //     errors.project = 'Enter project name'
        //     isValid = false;
        // }


        if (!reportFilter.LastMonth && (!reportFilter.FromDate || !reportFilter.ToDate)) {
            toast.error('Enter From & To Date');
            isValid = false;
        }


        if (reportFilter.LastMonth && (reportFilter.FromDate || reportFilter.ToDate)) {
            toast.error('You cannot choose both the Previous Month option and the From & To Date option simultaneously.');
            isValid = false;
        }


        if (!isValid) {
            setError(errors);
        }
        else {
            setError({});
        }
        return isValid;
    }

    const searchReport = () => {
        let isValid = validateData();
        isValid && setSearchButton(!searchButton)
    }

    const projectUserdata = (value) => {
        const adUser = adUsers.find((item) => item.displayName == value)
        setReportFilter({ ...reportFilter, UserId: adUser ? adUser.id : '' })
    }

    const clearFilters = () => {

        setReportFilter({ ...reportFilter, 'ProjectId': '', 'UserId': '', 'FromDate': '', 'ToDate': '', 'LastMonth': false });
        setData([]);
        setAdusers([]);
        fetchADUsers();
    }

    return (

        <div>

            {toggleContentVisibility &&
                <div className='flex gap-2 filter-sections bg-background px-6 py-4 md:px-2 ml-3 md:flex-wrap md:mr-3 '>

                    <div className=' md:-w-full'>
                        <div className='flex gap-2 filter-sections bg-background px-4 py-2 md:px-2 md:-flex-col md:items-baseline md:flex-col'>
                            <div className='md:w-full flex items-cenetr  gap-4'>
                                <div className='flex flex-col w-full'>
                                    <label className='text-[12px]'>Projects</label>
                                    <select className='border  text-[12px] w-full  h-[35px] rounded-md border-gray text-black  focus:appearance-show '
                                        id="selectBox"
                                        value={reportFilter?.ProjectId || ""}
                                        onChange={(e) =>
                                            setReportFilter({ ...reportFilter, ProjectId: e.target.value })
                                        }
                                    >
                                        <option value="">Select Project</option>
                                        {project?.map((option, index) => (
                                            <option key={index} value={option.id}>
                                                {option.name}
                                            </option>
                                        ))}

                                    </select>
                                </div>
                                <div className="flex flex-col w-full">
                                    <label className="text-[12px]">Team/Users</label>
                                    <select
                                        className="border  text-[12px]  h-[35px] rounded-md border-gray text-black  focus:appearance-show md:w-[200px] sm:w-[100%]"
                                        id="selectBox"
                                        // value={reportFilter?.projectUser || ""}
                                        onChange={(e) =>
                                            projectUserdata(e.target.value)
                                        }
                                    >
                                        <option value="">Select User</option>
                                        {(projectUser?.length > 0 ? projectUser : adUsers)?.map((option, index) => (
                                            <option key={index.id} value={option.displayName}>
                                                {option.displayName}
                                            </option>
                                        ))}
                                    </select>
                                </div>

                                <div className="flex flex-col w-full">
                                    <label className='text-[12px]'>Date From </label>
                                    <div className='flex gap-2 align-center'>
                                        <input type="Date" name="hours" id="hours" min="0" step="1" value={reportFilter?.FromDate}
                                            className="timeTextBox flex-grow pl-1 cursor-pointer  border  text-[12px] h-[35px]  rounded-md border-gray text-black"
                                            onChange={(e) => setReportFilter({ ...reportFilter, "FromDate": e.target.value })}></input>


                                    </div>
                                </div>
                                <div className="w-full">
                                    <div className="flex flex-col ">
                                        <label className='text-[12px]'>Date To </label>
                                        <input type="Date" name="hours" id="hours" min="0" step="1" value={reportFilter?.ToDate}
                                            className="timeTextBox  flex-grow cursor-pointer  pl-1  border h-[35px] rounded-md border-gray  text-black text-[12px]"
                                            onChange={(e) => setReportFilter({ ...reportFilter, "ToDate": e.target.value })}
                                        ></input>
                                    </div>

                                </div>

                                <div className='flex flex-grow flex-col  '>
                                    <label className='text-[12px] pt-[2px] w-[90px]'>Previous Month</label>
                                    <div className='gap-4 pl-1'>
                                        <input type="checkbox" id="checkbox" onChange={(e) =>
                                            setReportFilter({ ...reportFilter, "LastMonth": e.target.checked })
                                        } checked={reportFilter.LastMonth === true}

                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='md:w-full flex justify-center gap-4 mt-3'>
                            <div>
                                <Button label={'Search'} className={'hover:bg-blue-700 text-darkgray text-sm h-[35px]  font-medium py-1 px-4 rounded w-[200px] pt-1 md:w-full bg-yellow text-white'} onClick={searchReport} />
                            </div>
                            <div>
                                <Button label={'Clear'} className={'hover:bg-blue-700 text-darkgray bg-blue text-sm h-[35px]  font-medium py-1 px-4 rounded w-[200px] pt-1 md:w-full  text-white'} onClick={clearFilters} />
                            </div>
                        </div>
                    </div>





                </div>}

        </div>
    )
}

export default ReportFilter