import React, { useState, useEffect } from 'react'
import * as XLSX from 'xlsx';
import ReportFilter from '../ReportFilter'
import ReportTimeLog from '../ReportTimelog'
import axios from 'axios';
import { TfiLayoutListThumb } from "react-icons/tfi";
import { IoFilterSharp } from "react-icons/io5";
import { getLocalStorageData, clearLocalStorage } from '../../../constants/user';
import { CSSTransition } from 'react-transition-group';
import Loader from '../Loader';
import Pagination from '../Paginations';
import ShowMore from '../Showmore';
import moment from 'moment';
import { convertMinutesToHoursAndMinutes } from '../../../utils/convertMinToHrs';
import { toast } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';


const ReportItem = () => {
    const [isContentVisible, setIsContentVisible] = useState(false);
    const [reportFilter, setReportFilter] = useState([]);
    const [data, setData] = useState([]);
    const [searchButton, setSearchButton] = useState(false);
    const [dataLoading, setDataLoading] = useState(false);
    const [count, setCount] = useState(1);
    const [page, setPage] = useState(1);
    const [loader, setLoader] = useState(true);
    const headinglist = ['Time Spent', 'Project', 'User Name', 'Work Item ID', 'Date', 'Comment']
    const userId = getLocalStorageData('access_token');
    const [pageSize, setPagesize] = useState(10);

    const [loginData, setLoginData] = useState(null);
    const [projectName,setProjectName] = useState([])
    const [userName,setuserName] = useState([])
    const currentUserId = getLocalStorageData("access_token");

    useEffect(() => {
        const token = currentUserId !== null ? jwtDecode(currentUserId) : "";
        setLoginData(token);
    }, [currentUserId]);



    const toggleContentVisibility = () => {
        setIsContentVisible(!isContentVisible);
    }

   const projectData = (response)=>{
     setProjectName(response);
   }
   
    const userData = (response)=>{
        setuserName(response)
    }
    

      let projectLabel = projectName?.find((item) => item.id === reportFilter.ProjectId)?.name;
      
      let userNameLabel = userName?.find((item) => item.id === reportFilter.UserId)?.displayName;

    let message;
    if(reportFilter.ProjectId){
        message = projectLabel
    }
    else if(reportFilter.UserId){
        message = userNameLabel;
    }
    else{
        message = "Timelog"
    }

    const newData = data.map(item => ({
        'Time Spent': item.time ? convertMinutesToHoursAndMinutes(item.time) : '-',
        Project: item.project ? item.project : '-',
        "User Name": item.userName ? item.userName : '-',
        "Work Item ID": item.workItemId ? item.workItemId : '-',
        Date: item.date ? moment(item.date).format("DD-MM-YYYY") : '-',
        Comment: item.comment ? item.comment : '-',
        Page: page,
    }));

    const exportToExcel = () => {
        const filename = `${message}_reports`
        const ws = XLSX.utils.json_to_sheet(newData);
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, 'Sheet 1');
        XLSX.writeFile(wb, `${filename}.xlsx`);
    };
 

    const fetchData = async () => {
        setDataLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}TimeLog/GetProjectReports?currentUserId=${loginData?.oid}&${reportFilter?.ToDate ? `ToDate=${reportFilter.ToDate}&` : ''}${reportFilter?.FromDate ? `FromDate=${reportFilter.FromDate}` : ''}&UserId=${reportFilter?.UserId ? reportFilter?.UserId : ''}&ProjectId=${reportFilter?.ProjectId || ''}&LastMonth=${reportFilter?.LastMonth ? reportFilter?.LastMonth : false}&page=${page}&take=${pageSize}`);

            if(response.status == 200) {
                const sortedData = response?.data.sort((a, b) => new Date(a.date) - new Date(b.date));
                setData(sortedData);
                setCount(response?.data?.count);
            }
        }
        catch (error) {
            console.log(error);
            if (error?.response?.status === 400) {
                toast.error(error?.response?.data || 'Access denied');
            }
        }
        setDataLoading(false);
    };
   
     

    useEffect(() => {
        if (Object.keys(reportFilter).length !== 0) {
            fetchData();
        }
    }, [searchButton, page])
    useEffect(() => {
        setLoader(true);
    }, [page])


    return (
        <div className='ml-3 pr-4 md:pr-0 mb-4 sm:m-0'>
            <div className='m-3 mr-0 md:mr-3 sm:m-0'>
                <div className='flex items-center gap-3 justify-between py-2 ml-2'>
                    <div className='flex items-center gap-3'>
                        <TfiLayoutListThumb className='text-yellow timeicon text-2xl' />
                        <strong className='text-center text-xl'>Reports</strong>
                    </div>
                </div>
            </div>

            <CSSTransition
                in={!isContentVisible}
                timeout={300}
                classNames="fade"
                unmountOnExit
            >
                <>
                    <ReportFilter userData={userData} projectData={projectData} reportFilter={reportFilter} setReportFilter={setReportFilter} setData={setData} searchButton={searchButton} setSearchButton={setSearchButton} isContentVisible={isContentVisible} />

                    {
                        dataLoading ? (
                            <Loader />
                        ) : (
                            data.length > 0 ?
                                <ReportTimeLog data={data} exportToExcel={exportToExcel} headinglist={headinglist} />
                                : <div className="mt-6 text-lg text-center">No Record Found</div>
                        )
                    }


                </>


            </CSSTransition>

        </div>
    )
}

export default ReportItem