import React from 'react'
import image from '../../../asest/calnder.jpg'
function Calender() {
  return (
    <div>
        <div className='flex justify-center p-5 items-center'>
            <img alt='calender' src={image} />
        </div>
    </div>
  )
}

export default Calender