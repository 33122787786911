import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { Link, NavLink, useLocation } from 'react-router-dom'

const Breadcrumb = ({ active, breadcrumbData }) => {
  const [project, setProject] = useState([]);
  const [projectName, setProjectName] = useState('');

  const projectUrlId = window.location.pathname.split('/').filter(segment => segment)[1];

  const fetchProjectData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}TimeLog/GetAllProjects`);
      setProject(response.data.value);
    } catch (error) {
      console.log(error);
    }
  };


  useEffect(() => {
    fetchProjectData();
  }, []);

  useEffect(() => {
    if (project) {
      const selectedProject = project.find(pr => pr.id === projectUrlId);
      if (selectedProject) {
        setProjectName(selectedProject.name);
      }
    }
  }, [project, breadcrumbData]);




  return (
    <div className={`mt-2 ${active ? 'max-w-6xl bg-primary' : 'max-w-6xl bg-white'}`} data-testid={'breadcrumb'}>
      <nav className="flex gap-1 p-2 sm:px-4 flex-wrap">
        <span>TMotions &gt;</span>
        {breadcrumbData?.map((item, index) => (
          <div key={index}>
            {index === 1 && (
              <NavLink to={`/workitems/${projectUrlId}`}>
                <span>{projectName}</span>  &gt;
              </NavLink>
            )}

            <span>{item.name === 'Projects' ? <NavLink to={`/projects`}>
                <span>Projects </span>
              </NavLink>  : item.name }</span>
            {index < breadcrumbData.length - 1 && ' > '}
          </div>
        ))}
      </nav>
    </div>
  );
};

export default Breadcrumb;
