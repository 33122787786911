import React, { useState, useEffect } from 'react'
import TopBar from '../../Molecules/TopBar'
import Sidebar from '../../Molecules/Sidebar'
import FilterBar from '../../Atoms/FilterBar';
import AccessControlTable from '../../Molecules/AccessControlTable';
import Modal from "../../Atoms/Modals";
import axios from "axios";
import Pagination from '../../Atoms/Paginations';
import { jwtDecode } from "jwt-decode";
import { getLocalStorageData } from '../../../constants/user';
import { useSelector, useDispatch } from 'react-redux';
import { filter } from 'style-value-types';
import { updateFilter } from '../../../store/workItem';
import AccessPage from '../AccessPage';


function AccessControl() {
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [AccessControlInput, setAccessControlInput] = useState(true)
  const [page, setPage] = useState(1);
  const [count, setCount] = useState(1);
  const [pageSize, setPagesize] = useState(10);
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(true);
  const [loginData, setLoginData] = useState(null);
  const [filterData, setFilterData] = useState([]);
  const [editData, setEditData] = useState(null);
  const [menus, setMenusData] = useState([]);

  const workFilter = useSelector((state) => state.filter.workFilter);

  const currentUserId = getLocalStorageData("access_token");

  const dispatch = useDispatch();

  useEffect(() => {
    const token = currentUserId !== null ? jwtDecode(currentUserId) : "";
    setLoginData(token);
  }, [currentUserId]);

  useEffect(() => {
    if (loginData) {
      setLoader(true);
      fetchData();
      setLoader(false);
    }
  }, [loginData]);


  useEffect(() => {
    const hasFilterData = Object.keys(filterData).some(key => filterData[key]);
    if (hasFilterData) {
      dispatch(updateFilter({ ...filterData }));
    }
  }, [filterData])

  const breadcrumbData = [
    {
      name: 'Access Control',
      href: '/access-page-list',
    }
  ];


  
  const fetchMenusData = async () => {
    try {

        const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}AclRecord/GetPermissionMenu?userId=${loginData?.oid}`
        );

        if (response.status == 200) {
            setMenusData(response?.data)
            setLoader(false)

        }
    } catch (error) {
        console.log(error);
        setLoader(false)
    }
}

useEffect(() => {
    if (loginData) {
        fetchMenusData()
    }

}, [loginData])

  const fetchData = async () => {
    try {

      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}AclRecord/GetAllAclRecords`, {
        "page": page, "take": pageSize, userId: loginData?.oid, ...workFilter
      });

      if (response.status == 200) {
        setData(response?.data?.value);
        setCount(response?.data?.count)

      }
    }
    catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
    setLoader(false);
  }, [page, workFilter, pageSize])
   

  return <>
    <div className='flex  md:mt-0 md:flex-col'>
      <Sidebar sidebarVisible={sidebarVisible} setSidebarVisible={setSidebarVisible} />
      <div className='w-[85%] md:w-full flex-grow'>
        
      <TopBar breadcrumbData={breadcrumbData} />
        {menus && (menus.showManageAcl  || menus.showManageAcl === undefined) ? (
         
         data ? (
        <>
        <FilterBar AccessControlInput={AccessControlInput} filterData={filterData} setFilterData={setFilterData} />
        <AccessControlTable 
          data={data} 
          setData={setData} 
          loginData={loginData} 
          fetchData={fetchData} 
          
        />
        </>
    
  ) : (
    <div className='text-center font-semibold text-2xl text-blue mt-8'>
      No Data Found
    </div>
  )
     ) : (
      
       <AccessPage />
        )}
        {count > pageSize && <Pagination totalItems={count} setPage={setPage} itemsPerPage={pageSize} />}
      </div>
    </div>
  </>
}

export default AccessControl