import React, { useState, useEffect } from 'react'
import { Button, Heading } from "../../Atoms/index";
import axios from "axios";
import { toast } from "react-toastify";
import { TfiLayoutListThumb } from "react-icons/tfi";
import { current } from '@reduxjs/toolkit';
import { getLocalStorageData } from '../../../constants/user';
import { jwtDecode } from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

const Movetimelog = () => {
    const [project, setProject] = useState([]);
    const [timelogDetails, setTimelogDetails] = useState({});
    const [workItems, setWorkitems] = useState([]);
    const [loginData, setLoginData] = useState(null);


    const currentUserId = getLocalStorageData("access_token");

    useEffect(() => {
        const token = currentUserId !== null ? jwtDecode(currentUserId) : "";
        setLoginData(token);
    }, [currentUserId]);

    const fetchProjectData = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BASE_URL}TimeLog/GetAllProjects`);
            setProject(response.data.value);
        } catch (error) {
            console.log(error);
        }
    };

    const fetchData = async () => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}TimeLog/GetAllWorkItemByProjectId`, {
                "projectId": timelogDetails?.toProject
            });
            if (response.status == 200) {
                setWorkitems(response?.data?.data);
            }
        }
        catch (error) {
            console.log(error);
        }
    };


    const moveTimelog = async () => {
        const { fromProject, toProject, FromWorkItem, ToWorkItem, FromDate, ToDate } = timelogDetails;

        if (!fromProject || !toProject) {
            toast.error("Select Projects first");
        } else if (fromProject === toProject) {
            toast.error("From project and to project cannot be same");
        } else if (!FromWorkItem || !ToWorkItem) {
            toast.error("Select Workitems");
        } else if (FromDate && !ToDate) {
            toast.error("To Date is required when From Date is selected");
        } else if (new Date(ToDate) < new Date(FromDate)) {
            toast.error("To Date cannot be earlier than From Date");
        }
        else {
            const movetimelogPayload = {
                fromProject,
                toProject,
                FromWorkItem,
                ToWorkItem,
                FromDate: FromDate || undefined,
                ToDate: ToDate || undefined,
                currentUserId: loginData?.oid
            };
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_BASE_URL}TimeLog/MigrateTimeLog`,
                    movetimelogPayload
                );
                if (response?.status == 200) {
                    toast.success("Successfully moved");
                    setTimelogDetails({ ...timelogDetails, FromDate: '', ToDate: '' });
                }


            } catch (error) {
                console.log(error);
                if (error?.response?.status === 400) {
                    toast.error(error?.response?.data || 'Access denied');
                }
            };
        }
    }

    useEffect(() => {
        fetchProjectData();
    }, [])

    useEffect(() => {
        if (timelogDetails?.fromProject && timelogDetails?.toProject) {
            fetchData();
        } else {
            setWorkitems([]);
        }
    }, [timelogDetails?.fromProject, timelogDetails?.toProject]);

    const clearFilter = () => {
        setTimelogDetails({});
        setWorkitems([])
    }


    return <div className='w-full'>
        <div className='m-3 mr-0 md:mr-3'>
            <div className='flex items-center gap-3 justify-between py-2 ml-2'>
                <div className='flex items-center gap-3'>
                    <TfiLayoutListThumb className='text-yellow timeicon text-2xl' />
                    <strong className='text-center text-xl'>Move TimeLog</strong>
                </div>
            </div>
        </div>
        {

            <div className='flex gap-2 filter-sections bg-background px-6 py-4 md:px-2 ml-3 md:flex-wrap md:mr-3 sm:m-0 sm:block '>

                <div className='flex gap-2 filter-sections bg-background px-4 py-2 md:px-2 md:-flex-col md:items-baseline md:flex-col w-full'>
                    <div className=' md:w-full gap-4 flex md:flex-col'>
                        <div className='flex flex-col w-full'>
                            <label className='text-[12px]'>From Project</label>
                            <select className='border  text-[12px] h-[35px] rounded-md border-gray text-black  focus:appearance-show '
                                id="selectBox"
                                required
                                value={timelogDetails?.fromProject || ''}
                                onChange={(e) => setTimelogDetails({ ...timelogDetails, "fromProject": e.target.value })}
                            >
                                <option value="">Select an option</option>
                                {project?.map((option, index) => (
                                    <option key={index} value={option.id}>
                                        {option.name}
                                    </option>
                                ))}

                            </select>
                        </div>

                        <div className='flex flex-col w-full'>
                            <label className='text-[12px]'>To Project</label>
                            <select className='border  text-[12px] h-[35px] rounded-md border-gray text-black  focus:appearance-show '
                                id="selectBox"
                                value={timelogDetails?.toProject || ''}
                                onChange={(e) => setTimelogDetails({ ...timelogDetails, "toProject": e.target.value })}
                                disabled={!timelogDetails?.fromProject}
                            >
                                <option value="">Select an option</option>
                                {project?.map((option, index) => (
                                    <option key={index} value={option.id}>
                                        {option.name}
                                    </option>
                                ))}

                            </select>
                        </div>
                        <div className='flex flex-col w-[200px]'>
                            <label className='text-[12px]'>Work Items</label>
                            <select className='border  text-[12px] h-[35px] w-[200px] rounded-md border-gray text-black  focus:appearance-show'
                                id="selectBox"
                                value={timelogDetails?.FromWorkItem && timelogDetails?.ToWorkItem || ''}
                                onChange={(e) => setTimelogDetails({ ...timelogDetails, "FromWorkItem": e.target.value, "ToWorkItem": e.target.value })}
                            >
                                <option value="">Select an option</option>
                                {workItems?.map((option, index) => (
                                    <option key={index} value={option.workitemId}>
                                        {option.workitemId} -- {option.title}
                                    </option>
                                ))}
                            </select>
                        </div>

                        <div className="flex flex-col w-full">
                            <label className='text-[12px]'>Date From </label>
                            <div className='flex gap-2 align-center'>
                                <input type="Date" name="hours" id="hours" min="0" step="1" value={timelogDetails?.FromDate || ''}
                                    className="timeTextBox flex-grow pl-1 cursor-pointer  border  text-[12px] h-[35px]  rounded-md border-gray text-black"
                                    onChange={(e) => setTimelogDetails({ ...timelogDetails, "FromDate": e.target.value })}
                                ></input>
                            </div>
                        </div>

                        <div className="flex flex-col  w-full">
                            <label className='text-[12px]'>Date To </label>
                            <input type="Date" name="hours" id="hours" min="0" step="1" value={timelogDetails?.ToDate || ''}
                                className="timeTextBox  flex-grow cursor-pointer  pl-1  border h-[35px] rounded-md border-gray  text-black text-[12px]"
                                onChange={(e) => setTimelogDetails({ ...timelogDetails, "ToDate": e.target.value })}
                                disabled={!timelogDetails?.FromDate}

                            ></input>
                        </div>

                    </div>
                </div>

            </div>}
        <div className='text-center md:p-2'>
            <Button label={'Move'} className={'hover:bg-blue-700 text-darkgray text-sm h-[35px]  font-medium py-1 px-4 rounded mt-[20px] md:mt-[10px] pt-1 md:w-full bg-yellow text-white w-[140px]'} onClick={moveTimelog} />
            <Button label={'Clear'} className={'hover:bg-blue-700 text-darkgray text-sm h-[35px] ml-3 md:ml-0 font-medium py-1 px-4 rounded mt-[20px] md:mt-[10px] pt-1 md:w-full bg-blue text-white w-[140px]'} onClick={clearFilter} />
        </div>


    </div>
}

export default Movetimelog;



