import React from 'react'
import { Button } from '../../Atoms';
import { Link } from 'react-router-dom';


const AccessPage = ({ active, breadcrumbData }) => {
  return (
    <div className='flex justify-center align-middle mt-8' >
      <div class="w3-display-middle flex justify-center items-center flex-col ">
        <h1 class="w3-jumbo w3-animate-top w3-center text-lg"><code>Access Denied</code></h1>
        <h3 class="w3-center w3-animate-right">You do not have permission to view this page.</h3>
        <Link to="/projects" className={`bg-yellow hover:bg-blue-700 text-white text-sm h-[38px]  font-medium py-1 px-2 rounded mt-[20px] pt-2`}>
          View Projects
        </Link>
      </div>
    </div>
  );
};
export default AccessPage;


