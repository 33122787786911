import React, { useEffect, useState } from "react";

import { RxCross2 } from "react-icons/rx";
import { FaRegEdit } from "react-icons/fa";
import Modal from "../../Atoms/Modals/index";
import axios from "axios";
import { toast } from "react-toastify";
import AddAccessList from "../AddAccessList";
import { Link } from "react-router-dom";
import Loader from "../../Atoms/Loader";

function AccessControlTable({ data, setData, currentUserId, fetchData, loginData }) {

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [userId, setUserId] = useState(null);
  const [loader, setLoader] = useState(true);
  const [editData, setEditData] = useState(true);

  const handleDelete = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}AclRecord/DeleteAclRecord?UserId=${userId}&currentUserId=${loginData?.oid}`
      );
      if (response.status === 200) {
        setLoader(true)
        await fetchData()
        toast.success("Successfully deleted");
      }

    } catch (error) {
      console.log(error);
    }
    closeDeleteModal();
  };


  const openDeleteModal = (id, userId) => {
    setUserId(userId);
    setIsDeleteModalOpen(true);

  };

  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const onClickDelete = (item) => {
    openDeleteModal(item.id, item.userId);

  };


  useEffect(() => {
    setLoader(true)
    fetchData();
    setLoader(false);
  }, [currentUserId, userId])

  return (<>

    <div className='p-4'>
    <div>
        {!loader && data.length === 0 && (
            <Loader text="Loading..." />
          )}
        </div>
      <table class="w-full  md:overflow-auto md:inline-table">
      
      
        <thead class="p-4 bg-secbg border border-gray">
          <tr class="">
            <th class="text-sm p-4 text-center border border-gray ">S.No</th>
            <th class="text-sm p-4 text-center border border-gray ">Email</th>
            <th class="text-sm p-4 text-center border border-gray ">Name</th>
            <th class="text-sm p-4 text-center border border-gray ">Module</th>
            <th class="text-sm p-4 text-center border border-gray ">Action</th>
          </tr>
        </thead>
        <tbody class="p-4">
          

          {data?.map(item => (
            <tr class="border-b border border-gray">
              <td class="p-4 text-[12px] text-center border border-gray">{item.id}</td>
              <td class="p-4 text-[12px] text-center border border-gray">{item.userEmail}</td>
              <td class="text-sm p-4 text-center border border-gray ">{item.userName}</td>
              <td className="text-sm p-4 text-center border border-gray">
                {item.moduleName && (
                  <>
                    {item.moduleName.split(',').map((module, index) => (
                      <div key={index}>
                        {module.trim()}
                        <br />
                      </div>
                    ))}
                  </>
                )}
              </td>
              <td class="text-sm p-4 text-center border border-gray ">
                <div className='flex gap-2 items-center justify-center'>
                  <Link to={`/access-page-list?userId=${item.userId}`}>
                    <FaRegEdit
                      title="Edit"
                      className="text-xl"
                    />
                  </Link>
                  <RxCross2 title="Remove" className="text-red text-xl cursor-pointer" onClick={() => onClickDelete(item)} />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={closeDeleteModal}
        onDelete={handleDelete}
        isAccess={'isAccess'}
      />
    </div >
  </>
  )
}

export default AccessControlTable