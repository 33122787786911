import React from 'react'
import BreadCrumb from '../../Atoms/BreadCrumb'
import { FaSearch } from "react-icons/fa";
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { jwtDecode } from "jwt-decode";
import { Button, Heading } from '../../Atoms/index';
import { getLocalStorageData, clearLocalStorage } from '../../../constants/user';
import { FaSignOutAlt } from "react-icons/fa";
import LoginButton from '../../Atoms/LoginButton';
import UserName from '../../Atoms/UserName'

const TopBar = ({ breadcrumbData }) => {


  const navigate = useNavigate();
  const [loginData, setLoginData] = useState(null);
  const userId = getLocalStorageData('access_token');

  useEffect(() => {
    const token = userId !== null ? jwtDecode(userId) : '';
    setLoginData(token);
  }, [userId]);

  function getInitials(fullName) {
    const words = fullName.split(' ');
    const initials = words.map((word) => word.charAt(0)).join('');
    return initials.toUpperCase();
  }

  const logOut = () => {
    clearLocalStorage();
    navigate('/');
  }

  return (
    <div className='fixed-header'>
      <div className='flex items-center justify-between py-2 shadow'>
        <BreadCrumb breadcrumbData={breadcrumbData} />
        <div className='flex  items-center gap-2 pr-4 sm:hidden'>
          <div className='rounded-full px-3 flex items-center'>
            <span className={`text-sm px-[7px] py-[9px] h-9 w-9 text-white rounded-full text-center ${loginData ? 'bg-green' : 'bg-white'}`}>{loginData && getInitials(loginData?.name)}</span>
            <LoginButton />
          </div>
        </div>
      </div>
    </div>

  )
}

export default TopBar