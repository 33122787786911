import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import {
  setLocalStorageData,
  getLocalStorageData,
} from "../../../constants/user";
import { Button, Heading } from "../../Atoms/index";
import axios from "axios";
import { Link } from "react-router-dom";
import image from '../../../asest/Frame.png'
import logo from '../../../asest/TMotions_Logo_Black.png'
import Loader from "../../Atoms/Loader";
const Login = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const userId = getLocalStorageData('access_token');

  const local = `${window.location.origin}`;

  const fetchData = async (code) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BASE_URL}Login/GetToken`, { "Code": code, "Url": local });
      if (response.status == 200) {
        const { access_token, expires_in, refresh_token } = response?.data?.result;
        setLocalStorageData(
          access_token,
          "access_token"
        );
        setLocalStorageData(
          refresh_token,
          "refresh_token"
        );
        setLocalStorageData(
          new Date(new Date().getTime() + expires_in * 1000),
          "expiredTime"
        );
        navigate("/projects");
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (code) {
      fetchData(code)
    };
  }, [code, userId]);


  return (
    !code ?
      <div className="min-h-screen flex items-center justify-center m-4">
        <div className="flex bg-gray-100 p-8 rounded-md shadow-md shadow-zinc-400 sm:flex-col sm:p-4">
          <div className="md:w-1/2 md:pr-8 mb-4 md:mb-0 flex justify-center items-center sm:w-full sm:pr-0 sm:pb-2">
            <img src={image} className="lg:w-full w-100 h-auto max-h-96" alt="images" />
          </div>
          <div className="md:w-1/2 flex flex-col justify-center sm:w-full mt-2">
            <img src={logo} className="pb-3 h-auto mx-auto" alt="images" />

            <header className="text-3xl font-medium mb-3 text-center">Log in</header>
            <p className="text-gray-600 lg:mb-6 mb-3 text-center">
              Welcome to <b>TMotions Timelog</b>. Kindly enter your details.
            </p>
            <a
              href={`https://login.microsoftonline.com/e8679cd0-0462-4591-bfc5-a55bb33d5560/oauth2/v2.0/authorize?client_id=ebab61ff-1bec-44c9-82dd-f43c94a5a118&response_type=code&redirect_uri=${window.location.origin}/&response_mode=query&scope=openid%20offline_access%20https%3A%2F%2Fgraph.microsoft.com%2Fmail.read&state=12345`}
              className="LoginLink flex justify-center"
            >
              <Button
                label={"Login Using Microsoft"}
                class="darkgray hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2"
              />
            </a>
          </div>
        </div>
      </div> : <Loader text="Logging in may take a few seconds." />
  );
};

export default Login;