import React, { useEffect, useState } from 'react'
import Sidebar from '../../Molecules/Sidebar'
import TopBar from '../../Molecules/TopBar'
import Breadcrumb from '../../Atoms/BreadCrumb';
import TimelogRestriction from '../../Molecules/TimelogRestriction';
import axios from 'axios';
import AccessPage from '../AccessPage';
import { getLocalStorageData } from '../../../constants/user';
import { useSelector, useDispatch } from 'react-redux';
import { jwtDecode } from "jwt-decode";
import Loader from '../../Atoms/Loader';
function TimelogSetting() {
    const [sidebarVisible, setSidebarVisible] = useState(true);
    const [AccessControlInput, setAccessControlInput] = useState(true)
    const [menus, setMenusData] = useState([]);
    const [loader, setLoader] = useState(true);
    const [loginData, setLoginData] = useState(null);

    
  const currentUserId = getLocalStorageData("access_token");

  const dispatch = useDispatch();

  useEffect(() => {
    const token = currentUserId !== null ? jwtDecode(currentUserId) : "";
    setLoginData(token);
  }, [currentUserId]);
    const breadcrumbData = [
        {
            name: 'Setting',
            href: '/access-page-list',
        }
    ];
    const fetchMenusData = async () => {
      try {

          const response = await axios.get(
              `${process.env.REACT_APP_BASE_URL}AclRecord/GetPermissionMenu?userId=${loginData?.oid}`
          );

          if (response.status == 200) {
            
              setMenusData(response?.data)
              setLoader(false)

          }
      } catch (error) {
          console.log(error);
          setLoader(false)
      }
  }

  useEffect(() => {
      if (loginData) {
          fetchMenusData()
      }

  }, [loginData])

  if (loader) {
    return <div> <Loader text="loading..." /></div>;
}

  
    return <>
    <div className='flex  md:mt-0 md:flex-col'>
      <Sidebar sidebarVisible={sidebarVisible} setSidebarVisible={setSidebarVisible} />
      <div className='w-[85%] md:w-full flex-grow'>

        <TopBar breadcrumbData={breadcrumbData} />
        {menus && menus?.showSettings === true ? <TimelogRestriction /> : <AccessPage/>}
         
      </div>
    </div>
  </>
}

export default TimelogSetting