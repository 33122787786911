import React, { useState, useEffect } from "react";
import { GoDotFill } from "react-icons/go";
import { Input, Select } from "../index";
import axios from "axios";
import moment from 'moment';

const WorkStatus = ({ status, setStatus, data }) => {
  const [projectEnum, setProjectEnum] = useState([]);
  const [selectedOption, setSelectedOption] = useState("");

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}TimeLog/GetProjectAndWorkItemEnum`
      );
      setProjectEnum(response.data?.projectEnum);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    setStatus(data?.state);
  }, [data]);

  const handleSelectChange = (option) => {
    setStatus(option);
  };

  const calculateTime = (changedDate) => {
    const currentDate = moment();
    const changedDateMoment = moment(changedDate);
    const timeDifference = currentDate.diff(changedDateMoment);
    const duration = moment.duration(timeDifference);
    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();
    return (`${days} days, ${hours} hours, ${minutes} minutes, ${seconds} seconds`);
  }

  return (
    <>
      <div className="p-4 bg-background">
        <div className=" ">
          <div>
            <div className="flex gap-4 items-center md:flex-col md:items-baseline ">
              <div className="flex w-full  gap-8 md:flex-col md:gap-3">
                <div className="flex items-center gap-2">
                  <label className="text-[12px]">State:</label>
                  <div className="flex gap-1 items-center">
                    {/* <GoDotFill className='text-blue' /> */}
                    <Select
                      id="selectBox"
                      className="focus:outline-none focus:ring-blue-100 focus:ring-1  text-[12px] w-full max-w-[100px] text-green bg-background  "
                      value={status}
                      selectOptions={projectEnum}
                      onChange={(e) => handleSelectChange(e.target.value)}
                    />
                  </div>
                </div>
                <div className="flex items-center gap-2">
                  <label className="text-[12px] w-16 md:w-auto ">Area:</label>
                  <div className="flex gap-4 items-center w-full ">
                    <select
                      className="focus:outline-none focus:ring-blue-100 focus:ring-1  text-[12px] w-full max-w-[800px] bg-background appearance-none "
                      id="selectBox"
                    >
                      <option value="">{data?.area}</option>
                    </select>
                  </div>
                </div>
                <div className="flex  gap-2  items-center">
                  <label className="text-[12px]">Reason:</label>
                  <div className="flex gap-4 items-center">
                    <select
                      className="focus:outline-none focus:ring-blue-100 focus:ring-1 text-[12px] w-full max-w-[300px] bg-background  "
                      id="selectBox"
                    >
                      <option value={data?.reason}>{data?.reason}</option>
                    </select>
                  </div>
                </div>
                <div className="flex items-center gap-2 grow" >
                  <label className="text-[12px] w-[65px]">Iteration:</label>
                  <div className="flex gap-4 items-center w-full ">
                    <select
                      className="focus:outline-none focus:ring-blue-100 focus:ring-1  text-[12px] w-full max-w-[800px] bg-background appearance-none hover:select-all"
                      id="selectBox"
                    >
                      <option value="">{data?.iteaction}</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className="w-full max-w-[250px] grow">
                <label className="text-[12px]">
                  {`(Updated by ${data?.changedBy} ${calculateTime(data?.changedDate)} ago)`}
                </label>
              </div>
            </div>
            <div className="flex gap-4 items-center">
              <div className="w-full ">
                <label className="text-[12px]">{/* <Tabs /> */}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WorkStatus;
