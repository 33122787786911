import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';

const Pagination = ({ totalItems, setPage, itemsPerPage,handleClickPginate,page }) => {

  const [currentPage, setCurrentPage] = useState(page>0? page-1:0);
  const handlePageChange = ({ selected }) => {
    if(handleClickPginate){
      handleClickPginate(selected+1);
    }
    setCurrentPage(selected);
    setPage(selected + 1);
  
  };
 


  return (
    <div className='pagination-flex '>
      <ReactPaginate
        pageCount={Math.ceil(totalItems / itemsPerPage)}
        pageRangeDisplayed={5}
        marginPagesDisplayed={2}
        onPageChange={handlePageChange}
        containerClassName="pagination"
        activeClassName="active"
        forcePage={currentPage}
        previousLabel={currentPage === 0 ? null : "Previous"}
        nextLabel={currentPage === Math.ceil(totalItems / itemsPerPage) ? null : "Next"}
        
      />
    </div>
  );
};

export default Pagination;