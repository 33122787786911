import React, { useState } from 'react'
import TopBar from '../../Molecules/TopBar'
import Sidebar from '../../Molecules/Sidebar'
import FilterBar from '../../Atoms/FilterBar';
import AccessControlTable from '../../Molecules/AccessControlTable';
import Modal from "../../Atoms/Modals";
import AccessPage from '../AccessPage';
import AddAccessList from '../../Molecules/AddAccessList';
import Breadcrumb from '../../Atoms/BreadCrumb';
function AccessPageList() {
    const [sidebarVisible, setSidebarVisible] = useState(true);
    const [AccessControlInput, setAccessControlInput] = useState(true)
    const breadcrumbData = [
        {
            name: 'Access Control List',
            href: '/access-page-list',
        }
    ];
    return <>
        <div className='flex  md:mt-0 md:flex-col'>
            <Sidebar sidebarVisible={sidebarVisible} setSidebarVisible={setSidebarVisible} />
            <div className='w-[85%] md:w-full flex-grow'>
                <TopBar breadcrumbData={breadcrumbData} />
                <AddAccessList />
            </div>
        </div>
    </>
}

export default AccessPageList