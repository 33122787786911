import React, { useState, useEffect } from "react";
import { FiFilter } from 'react-icons/fi'
import { BsChevronDown } from 'react-icons/bs'
import { RxCross1 } from 'react-icons/rx'
import Button from '../Button'
import axios from "axios";
import Select from 'react-select';
import { Link, useParams } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { updateFilter } from '../../../store/workItem';
import { Heading } from "../../Atoms/index";

const text = [
  { label: 'Type', icon: <BsChevronDown /> },
  { label: 'Assigned to', icon: <BsChevronDown /> },
  { label: 'Area', icon: <BsChevronDown /> },
  { label: 'Tags', icon: <BsChevronDown /> }
];


const FilterBar = ({ filterData, setFilterData, AccessControlInput, setIsDeleteModalOpen, isDeleteModalOpen }) => {

  const receivedData = useParams();
  const { project_id } = receivedData;
  const [projectEnum, setProjectEnum] = useState([]);
  const [keyword, setKeyword] = useState('');
  const [assignedData, setAssignedData] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [selectedAssignedTo, setSelectedAssignedTo] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [clearInput, setClearInput] = useState('');

  const workFilter = useSelector((state) => state.filter.workFilter);
  const dispatch = useDispatch();

  useEffect(() => {
    if (project_id === workFilter.projectId) {
      setSelectedType(workFilter?.type?.map(item => ({
        label: item,
        value: item,
        checked: false
      })));


      setSelectedAssignedTo(workFilter?.assignedTo?.map(item => ({
        value: item,
        label: item
      })));

      setSelectedStatus(workFilter?.state?.map(item => ({
        value: item,
        label: item
      })));

      setKeyword(workFilter?.keyword)
    }

  }, []);


  const initialOptions = [
    { label: 'Task', value: 'Task', checked: false },
    { label: 'User Story', value: 'User Story', checked: false },
    { label: 'Epic', value: 'Epic', checked: false },
    { label: 'Bug', value: 'Bug', checked: false },
    { label: 'Issue', value: 'Issue', checked: false },
    { label: 'Subtask', value: 'Subtask', checked: false },
    { label: 'Test Case', value: 'Test Case', checked: false },
    { label: 'Test Plan', value: 'Test Plan', checked: false },
    { label: 'Test Suite', value: 'Test Suite', checked: false },
    { label: 'Feature', value: 'Feature', checked: false }
  ];

  const CheckboxOption = ({ innerProps, label, isSelected }) => (
    <div {...innerProps} style={{ cursor: 'pointer' }}>
      <input type="checkbox" checked={isSelected} readOnly className="m-1" />
      {label}
    </div>
  );


  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}TimeLog/GetProjectAndWorkItemEnum`
      );
      setProjectEnum(response.data?.projectEnum);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchAssignedData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}TimeLog/GetAllUsersByProject?ProjectId=${project_id}`
      );
      setAssignedData(response?.data?.value);
    } catch (error) {
      console.log(error);
    }
  };


  const clearFilter = () => {
    setSelectedType([]);
    setSelectedAssignedTo([]);
    setSelectedStatus([]);
    setKeyword('');
    setFilterData([]);
    dispatch(updateFilter([]));
  };


  useEffect(() => {
    fetchData();
    fetchAssignedData();
  }, []);

  const handleTypeChange = (selectedOptions, context) => {
    let combinedArray;

    if (context.action === 'select-option') {
      if (!filterData?.type) {
        combinedArray = selectedOptions;
      } else {
        const transformedArray1 = filterData?.type?.map(item => ({
          label: item,
          value: item,
          checked: false
        }));

        combinedArray = [...selectedOptions, ...transformedArray1];
      }
    } else if (context.action === 'remove-value') {
      const removedValue = context.removedValue;
      combinedArray = selectedOptions.filter(option => option.value !== removedValue.value);
    } else if (context.action === 'clear') {
      combinedArray = [];
    }

    const uniqueCombinedArray = Array.from(new Set(combinedArray.map(item => JSON.stringify(item)))).map(item => JSON.parse(item));
    setSelectedType(uniqueCombinedArray);
    setFilterData({ ...filterData, ...workFilter, "type": uniqueCombinedArray.map((item) => item.label) })
  };



  const handleAssignChange = (selectedOptions, context) => {

    let combinedArray;
    if (context.action === 'select-option') {
      if (!filterData?.assignedTo) {
        combinedArray = selectedOptions;
      } else {

        const transformedArray1 = filterData?.assignedTo?.map(item => ({
          value: item,
          label: item
        }));

        combinedArray = [...selectedOptions, ...transformedArray1];
      }
    } else if (context.action === 'remove-value') {
      const removedValue = context.removedValue;
      combinedArray = selectedOptions.filter(option => option.value !== removedValue.value);
    } else if (context.action === 'clear') {
      combinedArray = [];
    }

    const uniqueCombinedArray = Array.from(new Set(combinedArray.map(item => JSON.stringify(item)))).map(item => JSON.parse(item));

    setSelectedAssignedTo(uniqueCombinedArray);
    setFilterData({ ...filterData, ...workFilter, "assignedTo": uniqueCombinedArray.map((item) => item.label) })

  }

  const handleStatusChange = (selectedOptions, context) => {

    let combinedArray;
    if (context.action === 'select-option') {
      if (!filterData?.selectedStatus) {
        combinedArray = selectedOptions;
      } else {

        const transformedArray1 = filterData?.state?.map(item => ({
          value: item,
          label: item
        }));

        combinedArray = [...selectedOptions, ...transformedArray1];
      }
    } else if (context.action === 'remove-value') {
      const removedValue = context.removedValue;
      combinedArray = selectedOptions.filter(option => option.value !== removedValue.value);
    } else if (context.action === 'clear') {
      combinedArray = [];
    }

    const uniqueCombinedArray = Array.from(new Set(combinedArray.map(item => JSON.stringify(item)))).map(item => JSON.parse(item));

    setSelectedStatus(uniqueCombinedArray);
    setFilterData({ ...filterData, ...workFilter, "state": uniqueCombinedArray.map((item) => item.label) })
  }

  const handleKeyword = (keyword) => {
    setFilterData({ ...filterData, ...workFilter, "keyword": keyword ? keyword : '' })
    setKeyword(keyword)
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };
  const openDeleteModal = (event) => {
    setIsDeleteModalOpen(!isDeleteModalOpen)
  };


  return (
    <div>
      <div className="flex items-center justify-between">
        <Heading
          type={"h3"}
          className={`font-semibold text-xl ml-4 p-1`}
          text={"Filter by :"}
          dataTestId={"Best-seller"}
        />
        {AccessControlInput && <Link to="/access-page-list"> <Button label={'Add New'} className={`bg-yellow hover:bg-blue-700 px-3 text-white text-sm h-[38px] mr-8 mb-2 font-medium py-1  rounded mt-[20px] pt-1`} /></Link>}
      </div>

      {
        <div className='flex gap-2 items-center filter-sections bg-background px-6 py-4 ml-3 md:ml-0 md:mb-2  md:flex-wrap sm:flex-col'>
          {!AccessControlInput && <div className='filter-bar sm:w-full'>
            <div className='flex flex-col'>
              <label className='text-[12px] pb-1 '>ID or Title</label>
              <input type="search" placeholder='Enter ID or Title' className='border  text-[12px] w-full  h-[38px] rounded-[4px] border-gray text-black  focus:outline-none pl-2 pr-2'
                value={keyword}
                onChange={(e) => handleKeyword(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>}

          {!AccessControlInput && <div className='filter-bar sm:w-full'>
            <div className='flex flex-col'>
              <label className='text-[12px] pb-1'>Types</label>
              <Select
                options={initialOptions}
                isMulti
                value={selectedType}
                onChange={handleTypeChange}
                placeholder="Select options"
                className="basic-multi-select  text-[12px] "
                classNamePrefix="select "
                closeMenuOnSelect={false}
                hideSelectedOptions={true}
                components={{ Option: CheckboxOption }}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>}

          {!AccessControlInput && <div className='filter-bar sm:w-full'>
            <div className='flex flex-col'>
              <label className='text-[12px] pb-1'>Assigned to</label>
              <Select
                options={assignedData
                  ?.sort((a, b) => a.displayName.localeCompare(b.displayName))
                  .map((option) => ({ value: option.displayName, label: option.displayName }))
                }
                isMulti
                value={selectedAssignedTo}
                onChange={handleAssignChange}
                placeholder="Select options"
                className="basic-multi-select   text-[12px]"
                classNamePrefix="select"
                closeMenuOnSelect={false}
                hideSelectedOptions={true}
                components={{ Option: CheckboxOption }}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>}

          {!AccessControlInput && <div className='filter-bar sm:w-full'>
            <div className='flex flex-col'>
              <label className='text-[12px] pb-1'>Status</label>
              <Select
                options={projectEnum?.map(option => ({ value: option.id, label: option.enum }))}
                isMulti
                value={selectedStatus}
                onChange={handleStatusChange}
                placeholder="Select options"
                className="basic-multi-select  text-[12px]"
                classNamePrefix="select"
                closeMenuOnSelect={false}
                hideSelectedOptions={true}
                components={{ Option: CheckboxOption }}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>}
          {AccessControlInput && <div className='filter-bar email-search sm:w-full w-[350px] flex-grow-0'>
            <div className='flex flex-col'>
              <label className='text-[12px] pb-1 '>{AccessControlInput ? 'Email' : 'ID or Title'}</label>
              <input type="search" placeholder='Search Email or Name' className='border w-[350px]  text-[12px] sm:w-full  h-[38px] rounded-[4px] border-gray text-black  focus:outline-none pl-2 pr-2'
                value={keyword}
                onChange={(e) => handleKeyword(e.target.value)}
                onKeyDown={handleKeyDown}
              />
            </div>
          </div>}
          <Button label={'Reset'} className={`bg-yellow hover:bg-blue-700 text-white text-sm h-[38px]  font-medium py-1 px-1 rounded mt-[20px] pt-1 ${AccessControlInput ? 'clear-btn px-3' : ''}`} onClick={clearFilter} />
        </div>
      }

    </div>
  )
}

export default FilterBar